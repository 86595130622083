import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import { cx } from 'utils/functions';
import { FormFeedback } from './FormFeedback';
import { Label } from './Label';
import { RadioGroupContext, RadioGroupContextType } from './RadioGroupContext';

type RadioGroupProps = Omit<HTMLAttributes<HTMLElement>, 'onChange'> &
  RadioGroupContextType & {
    spacing?: number;
    direction?: 'row' | 'column';
    label?: string;
    error?: boolean;
    feedbackText?: string;
  };

export default function RadioGroup({
  children,
  label,
  value,
  onChange,
  className,
  spacing = 3,
  direction = 'column',
  error,
  feedbackText,
  ...props
}: RadioGroupProps) {
  const classes = classNames(
    'inline-grid auto-cols-auto',
    {
      [cx('gap', spacing)]: spacing,
      'grid-flow-col': direction === 'column',
      'grid-flow-row': direction === 'row',
    },
    className
  );
  return (
    <RadioGroupContext.Provider value={{ value, onChange }}>
      {label && <Label>{label}</Label>}
      <div className="radio-group-container">
        <div className={classes}>{children}</div>
      </div>
      {error && <FormFeedback invalid={error}>{feedbackText}</FormFeedback>}
    </RadioGroupContext.Provider>
  );
}
