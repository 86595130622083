import classNames from 'classnames';
import { HTMLInputTypeAttribute, InputHTMLAttributes } from 'react';

import { cx } from 'utils/functions';
import { ThemeColors, ThemeSizes } from 'utils/types';

export type InputTypes = HTMLInputTypeAttribute | 'select' | 'textarea';

export type InputProps = Omit<
  InputHTMLAttributes<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
  'size' | 'type'
> & {
  tag?: 'input' | 'select' | 'textarea';
  type?: InputTypes;
  color?: ThemeColors;
  size?: 'sm' | 'md' | 'lg';
  rounded?: ThemeSizes | 'full' | boolean;
  block?: boolean;
  border?: boolean;
  valid?: boolean;
  invalid?: boolean;
  indeterminate?: boolean | string;
  // eslint-disable-next-line
  innerRef?: any;
};

export function Input({
  tag: Tag = 'input',
  type = 'text',
  className,
  color = 'primary',
  size = 'md',
  rounded = 'md',
  border = true,
  block = true,
  valid,
  invalid,
  innerRef,
  indeterminate,
  ...props
}: InputProps) {
  if (type === 'select') {
    Tag = 'select';
  }
  if (type === 'textarea') {
    Tag = 'textarea';
  }

  const spacingClasses = {
    'px-2 py-2 text-xs': size === 'sm',
    'px-2 py-2 text-base': size === 'md',
    'px-6 py-3 text-lg': size === 'lg',
  };
  const inputClasses = {
    'border-white': !border,
    'border-success': valid,
    'border-danger': invalid,
    'w-full': block,
    [cx('rounded', rounded)]: rounded,
    checkbox: type === 'checkbox',
    radio: type === 'radio',
  };

  if (type && !['checkbox', 'radio'].includes(type)) {
    inputClasses['focus:border-primary'] = !valid && !invalid;
  }

  if (type && ['checkbox', 'radio'].includes(type)) {
    inputClasses[cx('checked:bg', color)] = !!color;
    inputClasses[cx('checked:border', color)] = !!color;
  }

  const classes = classNames('form-control', inputClasses, spacingClasses, className);

  if (type === 'checkbox') {
    // @ts-ignore
    props['indeterminate'] = indeterminate ? 'true' : 'false';
  }

  return <Tag className={classes} type={type} {...props} ref={innerRef} />;
}
