import { css } from '@emotion/css';
import { ColumnOrderState, Header, RowData, Table, flexRender } from '@tanstack/react-table';
import classNames from 'classnames';
import Icon from 'components/ui/Icon';
import { get } from 'lodash';
import { useDrag, useDrop } from 'react-dnd';
import { getCommonPinningStyles } from '../Table';

const reorderColumn = (
  draggedColumnId: string,
  targetColumnId: string,
  columnOrder: string[]
): ColumnOrderState => {
  columnOrder.splice(
    columnOrder.indexOf(targetColumnId),
    0,
    columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0] as string
  );
  return [...columnOrder];
};

type DraggableColumnHeaderProps<TData extends RowData> = {
  header: Header<TData, unknown>;
  tableInstance: Table<TData>;
  enableColumnDnD: boolean;
  left: number;
};

function DraggableColumnHeader<TData extends RowData>({
  header,
  tableInstance,
  enableColumnDnD,
  left,
}: DraggableColumnHeaderProps<TData>) {
  const { getState, setColumnOrder } = tableInstance;
  const { columnOrder } = getState();
  const { isPlaceholder, column, getContext, getResizeHandler } = header;

  const headerStyle = get(column.columnDef, 'headerStyle') || {};
  // @ts-expect-error
  const handleDoubleClick = (columnId) => () => {
    // const cells = document.querySelectorAll(`[data-columnid="${columnId.id}"]`);
    // const maxWidth = Array.from(cells).reduce((max, cell) => {
    //   const width = cell.scrollWidth;
    //   return width > max ? width : max;
    // }, 0);
    // Handle double-click action here, using columnId
  };
  const [, dropRef] = useDrop({
    accept: 'column',
    drop: (draggedColumn) => {
      // console.log('draggedColumn', draggedColumn, columnOrder);
      const newColumnOrder = reorderColumn(get(draggedColumn, 'id', ''), column.id, columnOrder);
      setColumnOrder(newColumnOrder);
    },
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => column,
    type: 'column',
  });

  const colStyles = getCommonPinningStyles(column, left);
  return (
    <div
      ref={dropRef}
      className={classNames(
        'th text-left',
        css({
          paddingRight: 16,
          opacity: isDragging ? 0.5 : 1,
          flexBasis: column.getSize(),
          overflow: 'hidden',
          ...headerStyle,
        })
      )}
      //@ts-expect-error
      style={colStyles}
      data-role="th"
    >
      <div className={'flex justify-between items-center py-4 px-2'} ref={previewRef}>
        <div
          className={' cursor-pointer select-none w-full'}
          onClick={column.getToggleSortingHandler()}
        >
          {/* Values */}
          {isPlaceholder ? null : flexRender(column.columnDef.header, getContext())}

          {/* Sorting */}

          {column.id !== 'select'
            ? {
                asc: (
                  <span>
                    <Icon name="sort-asc" className="pl-2 text-xs text-gray-500"></Icon>
                  </span>
                ),
                desc: (
                  <span>
                    <Icon name="sort-desc" className="pl-2 text-xs text-gray-500"></Icon>
                  </span>
                ),
              }[column.getIsSorted() as string] ?? (
                <Icon name="sort-default" className="pl-2 text-xs text-gray-500"></Icon>
              )
            : ''}
        </div>
        {/* Column DND */}
        {enableColumnDnD && column.id !== 'select' && (
          <button ref={dragRef} className={'text-xs cursor-grab pl-2'}>
            <Icon name="list" />
          </button>
        )}
      </div>
      {/* Resizeing */}
      {column.getCanResize() && (
        <div
          onDoubleClick={handleDoubleClick(column)}
          {...{
            onMouseDown: getResizeHandler(),
            onTouchStart: getResizeHandler(),
            className: `resizer ${column.getIsResizing() ? 'isResizing' : ''}`,
          }}
        />
      )}
    </div>
  );
}

export default DraggableColumnHeader;
