import classNames from 'classnames';
import { InputHTMLAttributes } from 'react';

export type RangeSliderProps = InputHTMLAttributes<HTMLInputElement> & {};

function RangeSlider({ type = 'range', className, ...props }: RangeSliderProps) {
  const classes = classNames('range-slider', className, {});
  return <input className={classes} type={type} {...props} />;
}

export default RangeSlider;
