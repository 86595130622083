import { default as classnames } from 'classnames';
import { HTMLAttributes } from 'react';
type RowProps = HTMLAttributes<HTMLElement> & {
  tag?: keyof HTMLElementTagNameMap;
  columns?: number;
  gap?: number;
};

export function Row({
  tag: Tag = 'div',
  children,
  className,
  columns = 12,
  gap = 4,
  ...props
}: RowProps) {
  const classes = classnames(
    'grid',
    { [`grid-cols-${columns}`]: columns },
    `gap-${gap}`,
    className
  );
  return (
    <Tag className={classes} {...props}>
      {children}
    </Tag>
  );
}
