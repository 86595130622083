import { HTMLAttributes } from 'react';
import { TabContext } from './TabContext';

export type TabsProps = HTMLAttributes<HTMLElement> & {
  tag?: keyof HTMLElementTagNameMap;
  activeTab: string | number;
};

export default function Tabs({ tag: Tag = 'div', activeTab, ...props }: TabsProps) {
  return (
    <TabContext.Provider value={{ activeTab }}>
      <Tag {...props} />
    </TabContext.Provider>
  );
}
