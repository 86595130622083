import { default as classNames } from 'classnames';
import { HTMLAttributes } from 'react';
import { cx } from 'utils/functions';

type CardBodyProps = HTMLAttributes<HTMLElement> & {
  tag?: keyof HTMLElementTagNameMap;
  px?: number;
  py?: number;
  noPadding?: boolean;
};

export function CardBody({
  tag: Tag = 'div',
  children,
  className,
  py = 4,
  px = 4,
  noPadding,
  ...props
}: CardBodyProps) {
  const cardBodyClasses = {
    [cx('px', px)]: !noPadding && px,
    [cx('py', py)]: !noPadding && py,
  };
  const classes = classNames(cardBodyClasses, className);
  return (
    <Tag className={classes} {...props}>
      {children}
    </Tag>
  );
}
