import classNames from 'classnames';
import { HTMLAttributes } from 'react';

type NavProps = HTMLAttributes<HTMLElement> & {
  tag?: keyof HTMLElementTagNameMap;
};

function Nav({ tag: Tag = 'div', className, ...props }: NavProps) {
  const classes = classNames('nav flex border-b', className);
  return <Tag className={classes} {...props} />;
}

export default Nav;
