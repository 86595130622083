export const STATUS_CODES = {
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
};

export const AUTH_SERVICE = {
  LOGIN: '/login/',
  VERIFY_OTP: '/verifyotp/',
  RESEND_OTP: '/resendotp/',
  EXPORT_LIST: '/exportListMail/',
  LOGOUT: '/logout/',
  // AUTH_SERVICE_ENDPOINTS
};

export const ALERT_SERVICE = {
  ALERT_LIST: '/listalerts',
  // ALERT_SERVICE_ENDPOINTS
};

export const MANAGE_USER_SERVICE = {
  GET_USER_LIST: '/user/list/',
  CREATE_NEW_USER: '/user/create/',
  GET_ROLE: '/user/add/',
  GET_USER: '/user/edit/',
  UPDATE_USER_INFO: '/user/update',
  USER_STATUS_ACTIVE: '/user/statususer/',
  UNIQUE_USER: '/user/isuniqueusername/',
  USER_DELETE: '/user/delete/',
  EDITASSIGNSITEGROUP: '/user/editassignsitegroup/',
  UPDATEASSIGNSITEGROUP: '/user/updateassignsitegroup/',
  // MANAGE_USER_SERVICE_ENDPOINTS
};

export const FORGOT_PASSWORD_SERVICE = {
  RESET_REQUEST: '/resetrequest/',
  RESET_PASSWORD: '/resetpassword/',
  FORGOT_PASSWORD: '/forgotpassword/',
  // FORGOT_PASSWORD_SERVICE_ENDPOINTS
};

export const CANNED_MESSAGES_SERVICE = {
  GET_LIST: '/canned/list/',
  ADD_CANNED_MESSAGES: '/canned/insert/',
  GET_CANNED_MESSAGE: '/canned/edit/',
  UPDATE_CANNED_MESSAGE: '/canned/update',
  DELETE_CANNED_MESSAGE: '/canned/delete/',
  // CANNED_MESSAGES_SERVICE_ENDPOINTS
};

export const MERCHANT_SERVICE = {
  GET_MERCHANT_LIST: '/merchant/list/',
  GET_ADD_DATA: '/merchant/add/',
  CREATE_MERCHANT: '/merchant/create/',
  MERCHANT_DELETE: '/merchant/delete/',
  GET_EDIT_DATA: '/merchant/edit/',
  UPDATE_MERCHANT: '/merchant/update/',
  // MERCHANT_SERVICE_ENDPOINTS
};
export const TEMPLATE_SERVICE = {
  GET_TEMPLATE_LIST: 'template/list/',
  ADD_TEMPLATE: 'template/add/',
  CREATE_TEMPLATE: 'template/create/',
  EDIT_TEMPLATE: 'template/edit/',
  DELETE_TEMPLATE: 'template/delete/',
  UPDATE_TEMPLATE: 'template/update/',
  // TEMPLATE_SERVICE_ENDPOINTS
};
export const RULE_SERVICE = {
  LIST: '/rule/list/',
  INSERT: '/rule/create/',
  ADD_RULE: '/rule/add/',
  GET_PARAMS_TYPE: '/rule/getParamsTypeByFieldID/',
  GET_PARAM_VALUE: '/rule/getParamValue/',
  ADD_ASSIGN_PARAMS: '/rule/addAssignParams/',
  INSERT_ASSIGN_PARAMS: '/rule/insertAssignParams/',
  ASSIGN_CONDITION: '/rule/addAssignCondition/',
  GET_CONDITION_BY_PARAMS_TYPE: '/rule/getConditionByParamsType/',
  INSERT_ASSIGN_CONDITION: '/rule/insertAssignCondition/',
  GET_POSNEG_DATA_BY_TYPE: '/rule/getPosnegDataByType/',
  EDIT: '/rule/edit/',
  RULE_AUDITLOG: '/rule/ruleauditlog/',
  UPDATE: '/rule/update/',
  RULE_AUTHENTICATE: '/rule/ruleauthenticate/',
  UPDATE_AUTHENTICATION: '/rule/updateauthentication/',
  DELETE: '/rule/delete/',
  THUMBSUP_AND_THUMBS_DOWN: '/rule/deleteAuthentication/',
  // RULE_SERVICE_ENDPOINTS
};
export const PSPID_SERVICE = {
  GET_PSPID_LIST: '/site/list/',
  GET_GROUP_LIST: '/site/add/',
  GET_PSPID: '/site/edit/',
  CREATE_PSPID: '/site/create/',
  UPDATE_PSPID: '/site/update/',
  DELETE_PSPID: '/site/delete/',
  IS_UNIQUE_SITE: '/site/unique/',
  SITE_WHITE_BLACK_LIST: '/sitewhiteblack/list/',
  DELETE_SITE_WHITE_BLACK_LIST: '/sitewhiteblack/delete/',
  ADD_SITE_WHITE_BLACK: '/sitewhiteblack/add/',
  CREATE_SITE_WHITE_BLACK: '/sitewhiteblack/create/',
  EDIT_SITE_WHITE_BLACK: '/sitewhiteblack/edit/',
  UPLOAD_SITE_WHITE_BLACK_LIST: '/sitewhiteblack/upload/list/',
  DELETE_UPLOAD_SITE_WHITE_BLACK: '/sitewhiteblack/upload/data/delete/',
  SITE_WHITE_BLACK_DATA_LIST: '/sitewhiteblack/data/list/',
  DELETE_SITE_WHITE_BLACK_DATA: '/sitewhiteblack/data/delete/',
  EXPORT_SITE_WHITE_BLACK_LIST: '/exportListMail/',
  // PSPID_SERVICE_ENDPOINTS
};
export const PLAN_SERVICE = {
  GET_PLAN_LIST: '/plan/list/',
  CREATE_PLAN: '/plan/create/',
  GET_PLAN: 'plan/edit/',
  UPDATE_PLAN: 'plan/update/',
  DELETE_PLAN: 'plan/delete/',
  // PLAN_SERVICE_ENDPOINTS
};

export const IGNORE_LIST_SERVICE = {
  GET_IGNORE_LIST: '/listignorelist/',
  FILTER_BY: '/filterby/',
  DONWLOAD_IGNORE_LIST_FILE: '/downloadignore/',
  DELETE_IGNORE_LIST: '/deleteignorelist/',
  ADD: '/add/',
  IGNORE_LIST_VIEW_UPLOAD: '/listignorelistupload/',
  CREATE_INGORE: '/uploadignorelist/',
  DELETE_IGNORE_LIST_UPLOAD: '/deleteignorelistupload/',
  IGNORE_LIST_DATA_TYPE: '/listignorelistdata/',
  EDIT_IGNORE_LIST: '/editignore/',
  DELETE_IGNORE_LIST_DATA_TYPE: '/deleteignorelistdata/',
  // IGNORE_LIST_SERVICE_ENDPOINTS
};
export const SITE_GROUP_SERVICE = {
  LIST_SITE_GROUP: 'sitegroup/list/',
  ADD_SITE_GROUP: 'sitegroup/add/',
  GET_SITE_GROUP: '/getsitegroup/',
  CHANGE_SITE_GROUP: '/changesitegroup/',
  CREATE_SITE_GROUP: '/sitegroup/create/',
  EDIT_SITE_GROUP: '/sitegroup/edit/',
  UPDATE_SITE_GROUP: '/sitegroup/update/',
  // SITE_GROUP_SERVICE_ENDPOINTS
};

export const TRANSACTION_LIST_SERVICE = {
  TRANSACTION_LIST: '/txn/gettxnlist/',
  EXPORT_MAIL: '/exportListMail/',
  QUEUE_ORGANISER_LIST: 'queueorganiser/list/',
  QUEUE_ORGANISER_DELETE: '/queueorganiser/delete/',
  QUEUE_ORGANISER_EDIT: '/queueorganiser/edit/',
  QUEUE_ORGANISER_INSERT: '/queueorganiser/insert/',
  QUEUE_ORGANISER_UPDATE: '/queueorganiser/update/',
  QUEUE_ORGANISER_ADDQUEUE: '/queueorganiser/addqueue/',
  TXN_AUTOCLOSE: '/txn/gettxnautoclose/',
  // TRANSACTION_LIST_SERVICE_ENDPOINTS
};
export const CARD_BIN_SERVICE = {
  GET_CARD_BIN_TYPES: '/selectcardbin/',
  GET_CARD_BIN_LIST: '/cardbin/list/',
  EDIT_CARDBIN: '/cardbin/edit/',
  GET_COUNTRY: '/getcountry/',
  UPDATE_CARD_BIN: '/cardbin/update/',
  CREATE_CARD_BIN: '/cardbin/create/',
  DOWNLOAD_SAMPLE_FILE: '/downloadsamplefile/',
  // CARD_BIN_SERVICE_ENDPOINTS
};
export const WB_LIST_TAB_SERVICE = {
  GET_WB_LIST: 'whiteblack/list/',
  ADD_WB_LIST: 'whiteblack/upload/create/',
  DELETE_WB_LIST: 'whiteblack/delete/',
  GET_BY_ID: '/whiteblack/data/list/',
  SELECT_LIST: '/whiteblack/select/',
  DELETE_DATA: '/whiteblack/data/delete/',
  WHITE_BLACK_DEFAULT_LIST: '/whiteblack/default/list/',
  UPDATE_WHITE_BLACK_DEFAULT_DATA: '/whiteblack/default/update/',
  EDIT_WHITEBLACK: '/whiteblack/upload/edit/',
  ADD_GLOBAL_LIST: '/whiteblack/globaldata/list/',
  GLOBAL_DATA_CREATE: '/whiteblack/globaldata/create/',
  DELETE_GLOBAL_SKIP_DATA: '/whiteblack/globalskip/delete/',
  WHITE_BLACK_REVERT_LIST: '/whiteblack/revert/list/',
  WHITE_BLACK_REVERT: '/whiteblack/revert/select/list/',
  REVERT_ROLLBACK: '/whiteblack/revert/delete/',
  DELETE_REVERT: '/whiteblack/revert/delete/',
  VIEWUPLOAD_DATA: '/whiteblack/upload/list/',
  DELETE_UPLOAD: '/whiteblack/upload/delete/',
  // WB_LIST_TAB_SERVICE_ENDPOINTS
};
export const EXTRA_FIELD_SERVICE = {
  EXTRA_FIELD_LIST: '/extrafields/list/',
  UPDATE_EXTRA_FIELD: '/extrafields/update/',
  // EXTRA_FIELD_SERVICE_ENDPOINTS
};
export const SQL_RUN_QUERY_SERVICE = {
  QUERY_RUN: '/queryrun/',
  // SQL_RUN_QUERY_SERVICE_ENDPOINTS
};
export const TEMPLATE_RULE_SERVICE = {
  LIST: '/templaterule/list/',
  ADD: '/templaterule/add/',
  EDIT: '/templaterule/edit/',
  CREATE_PARENT: '/templaterule/create/',
  CREATE_CHILD: '/templaterule/create/',
  IMPORT: '/templaterule/import/',
  IMPORT_CREATE_RULE: '/templaterule/createimportrule/',
  GET_TEMPLATE_RULE_BYTEMPLATE: '/templaterule/getTemplateRuleBytemplate/',
  AUDIT_LOG: 'templaterule/audit/',
  OTHER_TEMPLATE_RULE: '/templaterule/otherTemplateRules/',
  AUTH_LIST: '/templaterule/auth/',
  UPDATE: '/templaterule/update/',
  APPROVE_REJECT_AUTHENTICATION: '/templaterule/authentication/',
  DELETE: '/templaterule/delete/',
  DELETE_APPROVE_OR_REJECT: '/templaterule/deleteauthenticate/',
  CLONE: '/templaterule/clone/',
  // TEMPLATE_RULE_SERVICE_ENDPOINTS
};
export const ROLE_SERVICE = {
  GET_LIST: '/role/list/',
  EDIT_ID: '/role/edit/',
  CREATE_ROLE: '/role/create/',
  DELETE_ROLE: '/role/delete/',
  UPDATE_ROLE: '/role/update/',
  DUPLICATE_ROW: '/role/duplicate/',
  EDIT_ASSIGN_ROLE: '/role/permission/editassign/',
  UPDATE_ASSIGN_ROLE: '/role/permission/updateassignperm/',
  RESET_ROLE: 'role/permission/resetpermission/',

  // ROLE_SERVICE_ENDPOINTS
};

export const REVIEW_PANEL_SERVICE = {
  GET_LIST: '/reviewpanel/',
  TRANS_REVIEW: '/txn/txnReview/',
  // REVIEW_PANEL_SERVICE_ENDPOINTS
};

export const PERMISSIONS_SERVICE = {
  GET_LIST: '/permission/list/',
  ADD_PERMISSION: '/permission/add/',
  CREATE_PERMISSION: '/permission/create/',
  EDIT_PERMISSION: '/permission/edit/',
  UPDATE_PERMISSION: '/permission/update',
  DELETE_PERMISSION: '/permission/delete/',
  // PERMISSIONS_SERVICE_ENDPOINTS
};

export const PROFILE_INFO_SERVICE = {
  GET_LIST_INFO: '/profile/list/',
  EDIT_USER_INFO: '/profile/edit/user/',
  USER_PREF_DETAILS: '/profile/edit/userpref/',
  UPDATE_USER_DETAILS: '/profile/update/user/',
  UPDATE_USER_PREF: '/profile/update/userpref/',
  RESET_TABLE: '/profile/resettablepref/',
  CHANGE_PASSWORD: '/changepassword/',
  PROFILE_UPLOAD: '/profile/user/image/upload/',
  REMOVE_PROFILE: '/profile/user/image/remove/',
  // PROFILE_INFO_SERVICE_ENDPOINTS
};
export const MERCHANT_PLAN_SERVICE = {
  GET_LIST: '/merchantplan/list/',
  ADD_MERCHANT_PLAN_FORM: '/merchantplan/add/',
  PLAN_INFO_ID: '/merchantplan/info/',
  CREATE_MERCHANT_PLAN: '/merchantplan/create/',
  EDIT_MERCHANTPLAN: '/merchantplan/edit/',
  DELETE_MERCHANTPLAN: '/merchantplan/delete/',
  UPDATE_PLAN: '/merchantplan/update/',
  // MERCHANT_PLAN_SERVICE_ENDPOINTS
};

export const TRANSACTION_VIEW_SERVICE = {
  TXNVIEW: '/txn/gettxnView/',
  TXN_AUDIT_TRIAL: '/txn/getAuditTrial/',
  UPDATE_AUTOMATE_TXN_RA: '/txn/updateAutomateTxn/',
  REVIEWER_ASSISTANT: '/txn/reviewerAssistant/',
  IMPORTANT_STATS: '/txn/importantStats/',
  GET_DEVICE_BY_TXN_ID: '/txn/getDeviceByTxnId/',
  GET_EMAIL_BY_TXN_ID: '/txn/getEmailByTxnId/',
  GET_NEXUS_BY_TXN_ID: '/txn/getNexusByTxnId/',
  GET_CLUSTER_BY_TXN_ID: '/txn/getClusterByTxnId/',
  GET_PERSONA: '/txn/getPersonaByTxnId/',
  GET_STATS_BY_TXN_ID_VELOCITY_CHECK: '/txn/getStatsByTxnId/',
  GET_TXN_MAP: '/txn/getTxnMap/',
  GET_TXN_DETAILS: '/txn/getTxnDetails/',
  GET_UBA_DATA: '/txn/getUbaData/',
  ADDTXN_NOTES: '/txn/addtxnNotes/',
  ADDTXNWB: '/txn/addtxnwb/',
  SUBMITTXN_NOTES: '/txn/submittxnNotes/',
  TXN_REVIEW: '/txn/txnReview/',
  GET_CANNED_STATUS: '/txn/getCannedmsgByStatus/',
  STATUS_UPDATE: '/txn/statusUpdate/',
  TXN_MANUAL_REVIEW: '/txn/txnManualReview/',
  GET_TXN_LOG: '/txn/getTxnLog/',
  NOTIFY_STATUS: '/txn/notifyStatus/',
  INSERT_TXN_WHITE_BLACK: '/txn/inserttxnWB/',
  // TRANSACTION_VIEW_SERVICE_ENDPOINTS
};

export const SEARCH_SERVICE = {
  GET_BASIC_FIELD: '/basicsearch/getBasicFields/',
  BASIC_SEARCH_LIST: '/basicsearch/list/',
  GET_ADVANCE_FIELDS: '/advancesearch/getAdvanceFields/',
  GET_EXTRA_FIELDS: '/extrafields/list/',
  GET_SITES_BY_ACCOUNT_ID: '/advancesearch/getSitesByAid/',
  ADVANCE_SEARCH_LIST: '/advancesearch/list/',
  STATUS_UPDATE: '/txn/statusUpdate/',
  // SEARCH_SERVICE_ENDPOINTS
};
export const PREFERENCE_SERVICE = {
  EDIT_PREFERENCES: '/preference/edit/',
  UPDATE_PREFERENCE: '/preference/update/',
  VERIFY_URL: '/preference/verifyurl/',
  // PREFERENCE_SERVICE_ENDPOINTS
};
export const WB_GLOBAL_SERVICE = {
  WB_GLOBAL_LIST: '/globalwhiteblack/list/',
  DELETE_WB_GLOBAL: '/globalwhiteblack/delete/',
  WB_GLOBAL_DOWNLOAD: '/globalwhiteblack/download/',
  CREATE_WB_GLOBAL: '/globalwhiteblack/insert/',
  // WB_GLOBAL_SERVICE_ENDPOINTS
};
export const REDIS_SERVICE = {
  GET_LIST: '/redislist/',
  GET_REDIS_DATA: '/getredisdata/',
  // REDIS_SERVICE_ENDPOINTS
};

export const TRANSACTION_ROUTER_SERVICE = {
  TRANSACTION_ROUTER_LIST: '/transactionrouter/list/',
  ADD_TRANSACTION_ROUTER: '/transactionrouter/add/',
  CREATE_TRANSACTION_ROUTER: '/transactionrouter/create/',
  EDIT_TRANSACTION_ROUTER: '/transactionrouter/edit/',
  DELETE_TRANSACTION_ROUTER: '/transactionrouter/delete/',
  UPDATE_TRANSACTION_ROUTER: '/transactionrouter/update/',
  // TRANSACTION_ROUTER_SERVICE_ENDPOINTS
};

export const SITE_GROUP_SUMMARY_SERVICE = {
  SITE_GROUP_SUMMARY_LIST: '/sitegroupsummary/list/',
  // SITE_GROUP_SUMMARY_SERVICE_ENDPOINTS
};

export const PHONE_LOOKUP_SERVICE = {
  GET_SCORE: '/phonelookup/score/',
  GET_STATS: '/phonelookup/stats/',
  GET_STATS_DETAILS: '/phonelookup/stats/detials/',
  // PHONE_LOOKUP_SERVICE_ENDPOINTS
};

export const IP_LOOKUP_SERVICE = {
  GET_IP_LOOKUP_LIST: '/listip/',
  CREATE_IP_LOOKUP: '/addip/',
  IP_LOOKUP_FILE_UPLOAD: '/uploadip/?file',
  EDIT_IP_LOOKUP: '/editip/',
  UPDATE_IP_LOOKUP: '/updateip/',
  DELETE_IP_LOOKUP: '/deleteip/',
  GET_DATA_BY_IP: '/selectip/',
  DOWNLOAD_SAMPLE_FILE: '/downloadsamplefile/',
  // IP_LOOKUP_SERVICE_ENDPOINTS
};
export const NEXUS_VIEW_SERVICE = {
  GET_NEXUS_VIEW_LIST: '/nexus/list/',
  NEXUS_LABEL_UPDATE: '/nexus/labelupdate/',
  UPDATE_NEXUS: '/nexus/updatetxnnexus/',
  // NEXUS_VIEW_SERVICE_ENDPOINTS
};

export const NEXUS_SEARCH_SERVICE = {
  GET_NEXUS_SEARCH_LIST: '/nexus/search/list/',
  GET_NEXUS_SEARCH_FIELD: '/nexussearch/getNexusFields/',
  // NEXUS_SEARCH_SERVICE_ENDPOINTS
};
export const QUESTIONNARIE_SERVICE = {
  GET_LIST: '/questionnairepermission/list/',
  INSERT_USERS: '/questionnairepermission/insert/',
  TEMPLATE_QUESTION_LIST: '/templatequestion/list/',
  TEMPLATE_QUESTION_ADD: '/templatequestion/add/',
  TEMPLATE_QUESTION_ADD_IMPORT: 'templatequestion/addimport/',
  TEMPLATE_QUESTION_IMPORT: '/templatequestion/import/',
  TEMPLATE_QUESTION_EDIT: '/templatequestion/edit/',
  TEMPLATE_QUESTION_DELETE: '/templatequestion/delete/',
  TEMPLATE_QUESTION_INSERT: '/templatequestion/insert/',
  TEMPLATE_QUESTION_UPDATE: '/templatequestion/update/',
  FORMULA_LIST: '/formula/list/',
  FORMULA_ADD: '/formula/add/',
  FORMULA_EDIT: '/formula/edit/',
  FORMULA_DELETE: '/formula/delete/',
  FORMULA_DELETE_BY_CONDITION_ID: '/formula/deleteConditionByConditionId/',
  FORMULA_INSERT: '/formula/insert/',
  FORMULA_UPDATE: '/formula/update/',
  QUESTION_LIST: '/questionlist/list/',
  QUESTION_LIST_ADD: '/questionlist/add/',
  QUESTION_LIST_GET_RULES_BY_TEMPLATE_ID: '/questionlist/getRulesByTemplateId/',
  QUESTION_LIST_INSERT: '/questionlist/insert/',
  QUESTION_LIST_UPDATE: '/questionlist/update/',
  QUESTION_LIST_EDIT: '/questionlist/edit/',
  QUESTION_LIST_DELETE: '/questionlist/delete/',
  QUESTION_LIST_SELECT_FORMULA: '/questionlist/selectFormula/',
  QUESTION_LIST_DELETE_QUESTION_RULE_BY_RULE_QUESTION_ID:
    '/questionlist/deleteQuestionRuleByRuleQuestionID/',
  QUESTION_LIST_DELETE_QUESTION_FROMULA_RULE_BY_FORMULA_RULE_ID:
    '/questionlist/deletequestionformularule/',
  QUESTION_LIST_DELETEQUESTIONFORMULA: '/questionlist/deletequestionformula/',
  // QUESTIONNARIE_SERVICE_ENDPOINTS
};
export const PERSONA_LABEL_SERVICE = {
  GET_PERSONA_LABEL_LIST: '/persona/labelUpdate/',
  PERSONA_LABEL_UPLOAD: '/persona/upload/',
  // PERSONA_LABEL_SERVICE_ENDPOINTS
};
export const NEXUS_LABEL_SERVICE = {
  NEXUS_LABEL_LIST: '/nexuslabel/list/',
  NEXUS_LABEL_UPLOAD: '/nexuslabel/upload/',
  // NEXUS_LABEL_SERVICE_ENDPOINTS
};

export const PERSONA_SUMMARY_SERVICE = {
  VIEW: 'persona/getimportantStats/',
  PERSONA_DIAGRAM: '/persona/getDiagramData/',
  GET_TABLE_DATA: '/persona/getTxnTableData/',
  CHART_DATA: '/persona/getTxnChartData/',

  // PERSONA_SUMMARY_SERVICE_ENDPOINTS
};
export const NEXUS_DETAIL_SERVICE = {
  NEXUS_TXN_LIST: '/nexustxnlist/',
  ALL_NEXUS_RISK_CALCULATION: '/nexuslist/risk/',
  NEXUS_TAB_PREF: '/nexuslist/tablespref/',
  NEXUS_WB_LIST: '/nexuslist/wblist/',
  NEXUS_WB_DETAILS: '/nexuslist/wbdetails/',
  NEXUS_COUNT: '/nexuslist/count/',
  ADD_POSNEG_WBLIST: '/nexuslist/addwblist/',
  NEXUS_TXN_LIST_30_90_DAYS_TABS: '/nexustxnlistbyday/',
  UPDATE_NEXUS_MOVE_TO_NEXUS: '/nexuslist/updatenexus/',
  WHITE_BLACK_DETAILS: '/nexuslist/wbdetails/',
  // NEXUS_DETAIL_SERVICE_ENDPOINTS
};
export const PERSONA_SEARCH_SERVICE = {
  PERSONA_SEARCH: '/personasearch/getPersonaFields/',
  GET_PERSONA_SEARCH_LIST: '/personasearch/list/',
  PERSONA_SEARCH_LABEL_CHANGE: '/personasearch/labelChange/',
  // PERSONA_SEARCH_SERVICE_ENDPOINTS
};

export const EMAIL_LOOKUP_SERVICE = {
  GET_SCORE: '/emaillookup/score/',
  GET_STATS: '/emaillookup/stats/',
  GET_STATS_DETAILS: '/emaillookup/stats/detials/',
  TXN_EMAIL_PHONE_DETAILS: '/txn/gettxnemailphone/',
  // EMAIL_LOOKUP_SERVICE_ENDPOINTS
};
export const NEXUS_SUMMARY_SERVICE = {
  GET_NEXUS_DETAIL: '/nexussummary/nexusdetail/',
  NEXUS_SUMMARY_IMP_STATS: '/nexussummary/nexusstat/',
  GET_AFFECTED_RULES: '/nexussummary/rules/',
  GET_NEXUS_HISTORY: '/nexussummary/nexushistory/',
  GET_NEXUSSUMMARY_PERSONA_DETAIL: '/nexussummary/personadetails/',
  NEXUSSUMMARY_GET_TXN_LIST: '/nexussummary/gettxnlist/',
  // NEXUS_SUMMARY_SERVICE_ENDPOINTS
};
export const VIEW_LOG_SERVICE = {
  GET_VIEW_LOG_LIST: '/report/listreporttemplate/',
  GET_LOG_AND_DOWNLOAD_FILE: '/report/list/',
  SCHEDULE_REPORT_ACTIVE: '/report/scheduleactive/',
  CRON_STATUS_UPDATE: '/report/cronupdatestatus/',
  DELETE_VIEW_LOG_LIST: '/report/deletesavereport/',
  VIEW_LOG_DOWNLOAD_FILE: '/report/downloadfile/',
  VIEW_LOG_DELETE_QUEUE_LIST: '/report/deletequeue/',
  GET_ERROR_LOG: '/report/showerrorlog/',
  // VIEW_LOG_SERVICE_ENDPOINTS
};

export const NOTIFICATION_SERVICE = {
  GET_NOTIFICATIONS: '/notification/list/',
  DOWNLOAD_FILE: '/report/downloadfile/',
  DELETE_NOTIFICATION: '/notification/delete/',
  MARK_AS_READ: '/notification/status/',
  // NOTIFICATION_SERVICE_ENDPOINTS
};
export const CLUSTER_SEARCH_SERVICE = {
  GET_CLUSTER_SEARCH_LIST: '/cluster/clustersearchdata/',
  GET_CLUSTER_SEARCH_OPTIONS: '/cluster/clustersearchoptions/',
  CLUSTERMOVETO_LABEL: '/cluster/clustermovetoLabel/',
  // CLUSTER_SEARCH_SERVICE_ENDPOINTS
};
export const CLUSTER_VIEW_SERVICE = {
  GET_CLUSTER_LIST: '/cluster/list/',
  CLUSTER_LABEL_UPDATE: '/cluster/clustermovetoLabel/',
  // CLUSTER_VIEW_SERVICE_ENDPOINTS
};
export const REPORT_TEMPLATE_SERVICE = {
  GET_REPORT_TEMPLATE_LIST: '/report/listsavetemplatereport/',
  // REPORT_TEMPLATE_SERVICE_ENDPOINTS
};
export const SIMULATOR_SERVICE = {
  GET_SIMULATOR_LOG_LIST: '/simulatorlog/list/',
  DELETE_SIMULATOR_LOG: '/simulatorlog/delete/',
  SIM_GET_SOURCE_SITE_GROUP: '/externalsimulator/getSourceSiteGroups/',
  SIM_GET_DESTINATION_SITE_GROUP: '/externalsimulator/getDestinationSiteGroups/',
  SIM_GET_ACCOUNT_SITE: '/externalsimulator/getAccountSites/',
  INTERNAL_SIMULATOR_START: '/externalsimulator/simulatorStart/',
  GET_EXTERNAL_SIMULATOR_LIST: '/externalsimulator/list/',

  EXTERNAL_SIMULATOR_FILE_UPLOAD: '/externalsimulator/fileUpload/',
  CHECK_INTRAL_SIM_TXN: '/internalsimulator/checksimtxns/',
  DELETE_EXTRANL_SIMULATOR: '/externalsimulator/delete/',
  SIMULATOR_LOG_STOP: '/simulatorlog/simStop/',
  SIMULATOR_LOG_START: '/simulatorlog/simStart/',
  GET_LOG_BY_SIMLATOR_ID: '/simulatorlog/simLog/',
  // SIMULATOR_SERVICE_ENDPOINTS
};

export const REPORT_SERVICE = {
  REPORT_LIST: '/listreport/',
  REPORT_SEARCH: '/report/search/',
  RUN_REPORT: '/report/runreport/',
  RUN_TABLE_LIST: '/report/runtablelist/',
  SAVE_REPORT: '/report/savereport/',
  CHECK_VALID_PERSONA: '/report/checkvalidpersona/',
  UPDATE_SITE_LIST: '/report/updatesitelist/',
  GET_NEXUS_LABEL: '/report/nexus/labels/',
  GET_CLUSTER_LABEL: '/report/cluster/labels/',
  // REPORT_SERVICE_ENDPOINTS
};
export const CLUSTER_SUMMARY_SERVICE = {
  GET_CLUSTER_LOG: '/cluster/clusterlog/',
  CLUSTER_CHARACTERSTICS: '/cluster/clusterchar/',
  CLUSTER_SUMMARY: '/cluster/clustersummary/',
  GET_CLUSTER_COMMENTS: '/cluster/clustercomments/',
  GET_CLUSTER_TXN_LIST: '/cluster/clustertxnlist/',
  GET_CLUSTER_RELATION: '/cluster/clusterrelations/',
  GET_CLUSTERING: '/cluster/clusterring/',
  // CLUSTER_SUMMARY_SERVICE_ENDPOINTS
};
export const REPORT_ACCESS_SERVICE = {
  GET_ROLE_NAMES: '/permission/list-role/',
  EDIT_ASSIGN_REPORT: '/permission/assignreportpermission/',
  UPDATE_ASSIGN_REPORT: '/permission/updateassginreportpermission/',
  // REPORT_ACCESS_SERVICE_ENDPOINTS
};
export const GLOBAL_NEXUS_LIST_SERVICE = {
  GET_GLOBAL_NEXUS_LIST: '/globalnexus/list/',
  // GLOBAL_NEXUS_LIST_SERVICE_ENDPOINTS
};
export const GLOBAL_NEXUS_SUMMARY_SERVICE = {
  GET_QUICK_VIEW: '/globalnexus/quickview/',
  GET_GLOBAL_NEXUS_TXN_DETAILS: '/globalnexus/transaction/',
  GET_GLOBAL_NEXUS_HISTORY: '/globalnexus/history/',
  GET_GLOBAL_NEXUS_RISK_SCORE: '/globalnexus/riskscore/',
  GET_GLOBAL_NEXUS_IMP_STATS: '/globalnexus/importantstats/',
  // GLOBAL_NEXUS_SUMMARY_SERVICE_ENDPOINTS
};

export const BATCH_UPDATE_SERVICE = {
  BATCH_UPDATE_LIST: '/batchupdate/list/',
  BATCH_UPDATE_UPLOAD: '/batchupdate/upload/',
  BATACH_UPDATE_READ: '/batchupdate/read/',
  // BATCH_UPDATE_SERVICE_ENDPOINTS
};
export const UPLOAD_QUESTIONNARIE_SERVICE = {
  GET_TABLE_LIST: '/questionnaireupload/list/',
  ADD_TEMPLATE_LIST: '/questionnaireupload/add/',
  SAMPLE_FILE: '/downloadsamplefile/',
  INSERT_QUESTIONNARIE: '/questionnaireupload/upload/',
  DOWNLOAD_ACTION: '/report/downloadfile/',
  // UPLOAD_QUESTIONNARIE_SERVICE_ENDPOINTS
};

export const DASHBOARD_SERVICE = {
  GET_SITE_STATISTICS: '/dashboard/sitegrpstat/',
  RULE_AFFECT: '/dashboard/getDashboardData/',
  MY_REVIEW_CHARTS: '/dashboard/getDashboardData/',
  BIN_CARD: '/dashboard/getDashboardData/',
  KEY_PERFOMANCE_INDICATOR: '/dashboard/getDashboardData/',
  TOP_MANUAL_REVIEW: '/dashboard/getDashboardData/',
  TOP_FRAUD_REVIEW: '/dashboard/getDashboardData/',
  BROWSER: '/dashboard/getDashboardData/',
  TRANSACTION_HISTORY: '/dashboard/getDashboardData/',
  TRANSACTION_TREND: '/dashboard/getDashboardData/',
  UNIQUE_NEXUS: '/dashboard/getDashboardData/',
  CLUSTER_DASHBOARD: '/dashboard/getDashboardData/',
  POTENTIAL_FRAUD: '/dashboard/getDashboardData/',
  REVIEWER_ACTIVITY: '/dashboard/getDashboardData/',
  ALERT_POLICY: '/dashboard/getDashboardData/',
  ACTIVITY_LOG: '/dashboard/getDashboardData/',
  RESPONSE_TIME: '/dashboard/getDashboardData/',
  GET_SITES: '/dashboard/dashboardGetSites/',
  GET_TEMPLATE: '/dashboard/dashboardGetTemplates/',
  // DASHBOARD_SERVICE_ENDPOINTS
};

export const SITE_MIGRATION_SERVICE = {
  GET_LIST: '/sitemigration/list/',
  ADD_SOURCE: '/sitemigration/add/',
  SITES_BY_ACCOUNT_ID: '/sitemigration/getSitesByAccountId/',
  INSERT_SITE: '/sitemigration/insert/',
  MIGRATION_SUMMARY: '/sitemigration/migrationSummary/',
  REVERT: '/sitemigration/revert/',
  // SITE_MIGRATION_SERVICE_ENDPOINTS
};

export const SEARCH_FILTER_SERVICE = {
  SEARCH_FILTER_LIST: '/advancesearchbeta/list/',
  // SEARCH_FILTER_SERVICE_ENDPOINTS
};
export const QUESTIONNARIE_TEMPLATE_SERVICE = {
  GET_TEMPLATE: '/questionnaire/getQuestionTemplateCategory/',
  QUESTIONNARIE_TABLE: '/questionnaire/list/',
  NEW_QUESTIONNARIE: '/questionnaire/viewQuestionaire/',
  VIEW_AUDIT_LOG: '/questionnaire/questionaireauditlog/',
  AUTHENTICATION: '/questionnaire/questionAuthentication/',
  ADD_QUESTIONNARIE: '/questionnaire/updateQuestionaire/',
  UPDATE_AUTH: '/questionnaire/updateAuthentication/',
  UPDATE_QUESTIONNAIRE_BUTTON: '/questionnaire/updateQuestionaire/',
  VIEW_QUESTIONAIRE: '/questionnaire/viewQuestionaire/',
  // QUESTIONNARIE_TEMPLATE_SERVICE_ENDPOINTS
};
export const ALERTS_SERVICE = {
  ALERTS_LIST: '/alerts/list/',
  ALERTS_CREATE: '/alerts/create/',
  ALERTS_DELETE: '/alerts/delete/',
  ALERTS_EDIT: '/alerts/edit/',
  ALERTS_UPDATE: '/alerts/update/',
  ALERTS_ADD: '/alerts/add',
  ALERTS_EXPORTMAIL: '/exportListMail/',
  // ALERTS_SERVICE_ENDPOINTS
};

export const FAVOURITE_SERVICE = {
  ADD_OR_REMOVE: '/favourite/addorremove/',
  // FAVOURITE_SERVICE_ENDPOINTS
};

export const USER_ACTIVATE_SERVICE = {
  ACTIVATE_USER: '/user/useractivate/',
  // USER_ACTIVATE_SERVICE_ENDPOINTS
};

// SERVICE_CONSTANTS
