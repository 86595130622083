import classNames from 'classnames';
import { HTMLAttributes } from 'react';

type FormGroupProps = HTMLAttributes<HTMLElement> & {
  tag?: keyof HTMLElementTagNameMap;
};

export function FormGroup({ tag: Tag = 'div', className, ...props }: FormGroupProps) {
  const classes = classNames('mb-3', className);
  return <Tag className={classes} {...props} />;
}
