import { AUTH_SERVICE } from 'constants/serviceConstants';
import { AuthServiceType } from './AuthServiceTypes';
import BaseService from './BaseService';

const AuthService: AuthServiceType = {
  login: (payload) =>
    BaseService.post(`${AUTH_SERVICE.LOGIN}`, payload, { commonErrorHandling: false }),
  verifyOtp: (payload) => BaseService.post(AUTH_SERVICE.VERIFY_OTP, payload),
  resendOtp: (payload) => BaseService.post(AUTH_SERVICE.RESEND_OTP, payload),
  exportList: (payload) => BaseService.post(AUTH_SERVICE.EXPORT_LIST, payload),
  logout: () => BaseService.get(AUTH_SERVICE.LOGOUT),
  // AUTH_SERVICE_ENDPOINTS
};

export default AuthService;
