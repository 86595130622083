import PageLoader from 'components/section/PageLoader';
import React, { Suspense, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { history } from 'utils/functions';

const ProtectedLayout = React.lazy(() => import('./ProtectedLayout'));
const PublicLayout = React.lazy(() => import('./PublicLayout'));

const Layout = () => {
  history.navigate = useNavigate();
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

  const AppLayout = useMemo(() => {
    if (isAuthenticated) {
      return ProtectedLayout;
    } else {
      return PublicLayout;
    }
  }, [isAuthenticated]); // eslint-disable-line
  return (
    <Suspense fallback={<PageLoader />}>
      <AppLayout />
    </Suspense>
  );
};

export default Layout;
