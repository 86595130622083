import { AnyAction, combineReducers } from 'redux';
import { AsyncReducers } from '../index';
import authSlice from './authSlice';
import initSlice from './initSlice';
import commonSlice from './commonSlice';
// IMPORT_SLICE

// eslint-disable-next-line
type State = any;

const rootReducer = (asyncReducers?: AsyncReducers) => (state: State, action: AnyAction) => {
  const combinedReducer = combineReducers({
    [initSlice.name]: initSlice.reducer,
    [authSlice.name]: authSlice.reducer,
    [commonSlice.name]: commonSlice.reducer,
    ...asyncReducers,
  });

  if (action.type === 'auth/logout') {
    return combinedReducer(undefined, action);
  }

  return combinedReducer(state, action);
};

export default rootReducer;
