import classNames from 'classnames';
import { HTMLAttributes } from 'react';

type IconProps = HTMLAttributes<HTMLElement> & {
  tag?: keyof HTMLElementTagNameMap;
  name: string;
};

export default function Icon({ tag: Tag = 'i', className, name, ...props }: IconProps) {
  if (!name.startsWith('icon')) {
    name = `icon-${name}`;
  }
  return <Tag className={classNames(name, className)} {...props} />;
}
