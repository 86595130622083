import { default as classNames } from 'classnames';
import { HTMLAttributes } from 'react';

type CardTitleProps = HTMLAttributes<HTMLElement> & {
  tag?: keyof HTMLElementTagNameMap;
};

export function CardTitle({ tag: Tag = 'div', children, className, ...props }: CardTitleProps) {
  const classes = classNames(className);
  return (
    <Tag className={classes} {...props}>
      {children}
    </Tag>
  );
}
