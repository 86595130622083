/* eslint @typescript-eslint/no-explicit-any: 0 */
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import APISerive from './ApiService';

type CustomConfig<D> = AxiosRequestConfig<D> & {
  commonErrorHandling?: boolean;
};

type BaseServiceType = {
  get: <T = any, R = AxiosResponse<T, any>, D = any>(
    url: string,
    config?: CustomConfig<D> | undefined
  ) => Promise<R>;
  post: <T = any, R = AxiosResponse<T, any>, D = any>(
    url: string,
    data?: D | undefined,
    config?: CustomConfig<D> | undefined
  ) => Promise<R>;
  put: <T = any, R = AxiosResponse<T, any>, D = any>(
    url: string,
    data?: D | undefined,
    config?: CustomConfig<D> | undefined
  ) => Promise<R>;
  delete: <T = any, R = AxiosResponse<T, any>, D = any>(
    url: string,
    config?: CustomConfig<D> | undefined
  ) => Promise<R>;
};

const BaseService: BaseServiceType = {
  get: APISerive.get,
  post: APISerive.post,
  put: APISerive.put,
  delete: APISerive.delete,
};

export default BaseService;
