import { css } from '@emotion/css';
import moment from 'moment';
import { forwardRef, useCallback, useMemo, useRef, useState } from 'react';
import ReactDatePicker, {
  CalendarContainer,
  CalendarContainerProps,
  ReactDatePickerProps,
} from 'react-datepicker';
import { ThemeSizes } from 'utils/types';
import { Button } from './Button';
import { Col } from './Col';
import { FormField } from './FormField';
import Icon from './Icon';
import { Input } from './Input';
import { Row } from './Row';

type Ranges<WithRange extends boolean | undefined = undefined> = {
  [key: string]: WithRange extends false | undefined ? Date | null : [Date | null, Date | null];
};

export type DatepickerProps<
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined
> = ReactDatePickerProps<CustomModifierNames, WithRange> & {
  size?: 'sm' | 'md' | 'lg';
  rounded?: ThemeSizes | 'full' | boolean;
  block?: boolean;
  border?: boolean;
  valid?: boolean;
  invalid?: boolean;
  design?: 'date-picker' | 'date-range-picker';
  ranges?: Ranges<WithRange>;
};

type DateRangeContainerType<
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined
> = CalendarContainerProps & {
  ranges?: Ranges<WithRange>;
  design?: 'date-picker' | 'date-range-picker';
  pickerProps: ReactDatePickerProps<CustomModifierNames, WithRange>;
  active: string;
  setActive: (active: string) => void;
  DatePickerAPI: ReactDatePicker<CustomModifierNames, WithRange> | null;
  startDate?: Date | null;
  endDate?: Date | null;
  showTimeSelect?: boolean;
};

let today = moment().startOf('day');
let yesterday = moment().startOf('day').subtract(1, 'day');
// let tomorrow = moment().add(1, 'day');

const defaultRanges = {
  Today: [today.toDate(), today.endOf('day').toDate()],
  Yesterday: [yesterday.toDate(), yesterday.endOf('day').toDate()],
  // Tomorrow: [tomorrow.toDate(), tomorrow.endOf('day').toDate()],
  'This Month': [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
  'Last Month': [
    moment().subtract(1, 'month').startOf('month').toDate(),
    moment().subtract(1, 'month').endOf('month').toDate(),
  ],
  'This Year': [moment().startOf('year').toDate(), moment().endOf('year').toDate()],
};

function DateRangeContainer<
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined
>({
  children,
  ranges,
  pickerProps,
  design,
  active,
  setActive,
  DatePickerAPI,
  startDate,
  endDate,
  showTimeSelect,
  ...props
}: DateRangeContainerType<CustomModifierNames, WithRange>) {
  const { onChange } = pickerProps;

  if (design !== 'date-range-picker') return <CalendarContainer {...props} children={children} />;

  return (
    <CalendarContainer {...props}>
      <div className={'flex'}>
        <div className="border-r border-slate-300 w-32">
          {Object.entries(ranges || defaultRanges).map(([label, value]) => {
            const isActive = label === active;
            return (
              <div
                className={`p-2 cursor-pointer ${
                  isActive ? 'bg-primary text-white' : 'hover:bg-primary hover:text-white'
                }`}
                onClick={(e) => {
                  onChange(value, e);
                  setActive(label);
                }}
                key={label}
              >
                {label}
              </div>
            );
          })}
          <div
            className={`p-2 cursor-pointer ${
              active === 'Custom Date'
                ? 'bg-primary text-white'
                : 'hover:bg-primary hover:text-white'
            }`}
            onClick={(e) => {
              // @ts-ignore
              onChange([null, null], e);
              DatePickerAPI?.setBlur();

              setActive('Custom Date');
            }}
          >
            Custom Date
          </div>
        </div>
        <div className="flex flex-col">
          <div className={css({ position: 'relative' })}>{children}</div>
          {showTimeSelect && (
            <div className="flex">
              <div className="w-full flex px-2">
                <Row className="w-full">
                  <Col size={6}>
                    <FormField
                      type="select"
                      folding
                      label="Hours"
                      inputProps={{
                        value: moment(startDate).get('hour'),
                        disabled: !startDate,
                        children: Array.from(Array(24)).map((_, i) => {
                          return (
                            <option value={i}>{i.toString().length === 1 ? '0' + i : i}</option>
                          );
                        }),
                        onChange: ({ target }) => {
                          const startDateWithTime = moment(startDate).set(
                            'hour',
                            Number(target.value)
                          );
                          // @ts-ignore
                          onChange([startDateWithTime.toDate(), endDate]);
                        },
                      }}
                    />
                  </Col>
                  <Col size={6}>
                    <FormField
                      type="select"
                      folding
                      label="Minutes"
                      inputProps={{
                        value: moment(startDate).get('minutes'),
                        disabled: !startDate,
                        children: Array.from(Array(60)).map((_, i) => {
                          return (
                            <option value={i}>{i.toString().length === 1 ? '0' + i : i}</option>
                          );
                        }),
                        onChange: ({ target }) => {
                          const startDateWithTime = moment(startDate).set(
                            'minutes',
                            Number(target.value)
                          );
                          // @ts-ignore
                          onChange([startDateWithTime.toDate(), endDate]);
                        },
                      }}
                    />
                  </Col>
                </Row>
              </div>
              <div className="w-full flex px-2">
                <Row className="w-full">
                  <Col size={6}>
                    <FormField
                      type="select"
                      folding
                      label="Hours"
                      inputProps={{
                        value: moment(endDate).get('hour'),
                        disabled: !endDate,
                        children: Array.from(Array(24)).map((_, i) => {
                          return (
                            <option value={i}>{i.toString().length === 1 ? '0' + i : i}</option>
                          );
                        }),
                        onChange: ({ target }) => {
                          const endDateWithTime = moment(endDate).set('hour', Number(target.value));
                          // @ts-ignore
                          onChange([startDate, endDateWithTime.toDate()]);
                        },
                      }}
                    />
                  </Col>
                  <Col size={6}>
                    <FormField
                      type="select"
                      folding
                      label="Minutes"
                      inputProps={{
                        value: moment(endDate).get('minutes'),
                        disabled: !endDate,
                        children: Array.from(Array(60)).map((_, i) => {
                          return (
                            <option value={i}>{i.toString().length === 1 ? '0' + i : i}</option>
                          );
                        }),
                        onChange: ({ target }) => {
                          const endDateWithTime = moment(endDate).set(
                            'minutes',
                            Number(target.value)
                          );
                          // @ts-ignore
                          onChange([startDate, endDateWithTime.toDate()]);
                        },
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="text-right border-t border-slate-300 p-2">
        <Button
          size="sm"
          color="primary"
          onClick={() => {
            DatePickerAPI?.setOpen(false);
          }}
        >
          Apply
        </Button>
      </div>
    </CalendarContainer>
  );
}

function Datepicker<
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined
>({
  customInput,
  size,
  block = true,
  border = true,
  valid,
  invalid,
  design,
  ranges,
  calendarContainer,
  ...props
}: DatepickerProps<CustomModifierNames, WithRange>) {
  const [active, setActive] = useState<string>('Custom Date');
  const DPRef = useRef<ReactDatePicker<CustomModifierNames, WithRange>>(null);

  const calendarContainerProp = useCallback(
    (CCProps: CalendarContainerProps) => {
      if (calendarContainer) return calendarContainer(CCProps);

      return DateRangeContainer({
        ...CCProps,
        ranges,
        design,
        pickerProps: props,
        active,
        setActive,
        DatePickerAPI: DPRef.current,
        startDate: props.startDate,
        endDate: props.endDate,
        showTimeSelect: props.showTimeSelect,
      });
    },
    [calendarContainer, ranges, active, setActive, props.startDate, props.endDate] //eslint-disable-line
  );

  if (design === 'date-range-picker') {
    props.monthsShown = 2;
    props.shouldCloseOnSelect = false;
  }

  const defaultCustomInput = useMemo(() => {
    if (customInput) return customInput;

    const ELM = forwardRef((props, ref) => (
      <div className="relative items-center">
        <Icon
          name="calendar"
          className="icon-calendar text-white absolute top-1/2 left-6 transform -translate-y-1/2"
        />

        <Input
          style={{
            paddingLeft: '50px',
            backgroundColor: '#56b700',
            color: 'white',
            borderColor: '#56b700',
          }}
          size={size}
          block={block}
          border={border}
          valid={valid}
          invalid={invalid}
          innerRef={ref}
          {...props}
        />
      </div>
    ));

    return <ELM />;
  }, [customInput, size, block, border, valid, invalid]);

  return (
    <ReactDatePicker
      ref={DPRef}
      customInput={defaultCustomInput}
      calendarContainer={calendarContainerProp}
      {...props}
      showTimeSelect={design === 'date-range-picker' ? false : props.showTimeSelect}
    />
  );
}

export default Datepicker;
