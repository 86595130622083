import { css } from '@emotion/css';
import classNames from 'classnames';
import { themeConfig } from 'config/themeConfig';
import { get } from 'lodash';
import { HTMLAttributes } from 'react';

type SpinnerType = HTMLAttributes<HTMLElement> & {
  size?: number;
};

function Spinner({ size = 32, ...props }: SpinnerType) {
  const classes = classNames(
    css({
      borderColor: get(themeConfig, 'colors.primary'),
      borderWidth: 4,
      borderStyle: 'solid',
      height: size,
      width: size,
      borderRadius: size / 2,
      borderTopColor: 'transparent',
    }),
    'animate-spin'
  );
  return <div className={classes} />;
}

export default Spinner;
