import { default as classNames } from 'classnames';
import { HTMLAttributes } from 'react';

type ColProps = HTMLAttributes<HTMLElement> & {
  tag?: keyof HTMLElementTagNameMap;
  size: number;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
};

export function Col({
  tag: Tag = 'div',
  children,
  className,
  size,
  xs,
  sm,
  md,
  lg,
  xl,
  ...props
}: ColProps) {
  const breakpoints = {
    [`sm:col-span-${sm}`]: sm,
    [`md:col-span-${md}`]: md,
    [`lg:col-span-${lg}`]: lg,
    [`xl:col-span-${xl}`]: xl,
    [`col-span-${size || xs}`]: size || xs,
  };
  const classes = classNames(breakpoints, className);
  return (
    <Tag className={classes} {...props}>
      {children}
    </Tag>
  );
}
