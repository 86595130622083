import { ENCRYPTION_KEY } from 'constants/index';
import CryptoJS from 'crypto-js';

const key = CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY);
const iv1 = CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY);

export const encrypt = (text: string) => {
  const encrypted = CryptoJS.AES.encrypt(text, key, {
    keySize: 16,
    iv: iv1,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });

  return encrypted.toString();
};

export const decrypt = (cipher: string) => {
  const decrypted = CryptoJS.AES.decrypt(cipher, key, {
    keySize: 16,
    iv: iv1,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
};
