import { createContext } from 'react';
import { InputProps } from './Input';

type Value = InputProps['value'];

export type RadioGroupContextType = {
  value?: Value;
  onChange?: (value: Value) => void;
};

export const RadioGroupContext = createContext<RadioGroupContextType>({});
