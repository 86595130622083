import { RowData, Table } from '@tanstack/react-table';
import { Col } from 'components/ui/Col';
import { Row } from 'components/ui/Row';
import { Select } from 'components/ui/Select';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import Pagination from '../Pagination';

type PaginationContainerProps<TData extends RowData> = {
  tableInstance: Table<TData>;
  server?: string;
  globalFilter: string;
  sorting: {};
  totalCount?: number;
  getData?: (page: number, type: string, searchstring: string, sorting: {}) => void;
  showPagination?: boolean;
  showRecordperPage?: boolean;
};

function PaginationContainer<TData extends RowData>({
  tableInstance,
  server,
  totalCount,
  showPagination,
  showRecordperPage,
  sorting,
  globalFilter,
  getData = () => {},
}: PaginationContainerProps<TData>) {
  const {
    getState,
    setPageSize,
    getRowModel,
    getCoreRowModel,
    getPageCount,
    setPageIndex,
    getCanNextPage,
    getCanPreviousPage,
    nextPage,
    previousPage,
    getFilteredRowModel,
  } = tableInstance;
  const { pagination } = getState();
  const { pageIndex, pageSize } = pagination;
  const getTotalPage = () => {
    if (totalCount && totalCount > 0) {
      return Math.ceil(totalCount / pagination.pageSize);
    } else {
      return 1;
    }
  };
  const [current, setCurrent] = useState(1);

  const changePage = (val: number) => {
    setCurrent(val);
    getData(val, 'pageChange', globalFilter, sorting);
  };

  const changePagenext = (val: string) => {
    if (totalCount && totalCount > 0) {
      let total = Math.ceil(totalCount / pagination.pageSize);
      if (val === 'next' && current < total) {
        setCurrent(current + 1);
        getData(current + 1, 'pageChange', globalFilter, sorting);
      } else if (val === 'previous' && current > 1) {
        setCurrent(current - 1);
        getData(current - 1, 'pageChange', globalFilter, sorting);
      }
    }
  };

  useEffect(() => {
    if (server === 'true') {
      getData(pagination.pageSize, 'sizeChange', globalFilter, sorting);
      setCurrent(1);
    }
  }, [pagination.pageSize]); // eslint-disable-line
  const [state, setState] = useState(false);

  useEffect(() => {
    if (state && server === 'true') {
      setCurrent(1);
      getData(pagination.pageSize, 'sizeChange', globalFilter, sorting);
    } else {
      setState(true);
    }
    // you know what is this, don't you?
  }, [globalFilter, sorting]); // eslint-disable-line
  return (
    <Row>
      <Col size={4}>
        {!showPagination && (
          <div className="flex">
            {showRecordperPage && <div className="mt-2 mr-2">Record per page</div>}

            <Select
              menuPlacement="auto"
              value={{ label: pagination.pageSize, value: pagination.pageSize }}
              options={[10, 25, 50, 100, 500, 1000, 2000, 5000].map((i) => ({
                label: i,
                value: i,
              }))}
              onChange={(option) => {
                setPageSize(get(option, 'value', 0));
              }}
            />
          </div>
        )}
      </Col>
      <Col size={4} className="text-center">
        <div>
          {server !== 'true' ? (
            <span>
              Showing {pageIndex * pageSize + (getCoreRowModel().flatRows.length > 0 ? 1 : 0)} to{' '}
              {pageIndex * pageSize + getRowModel().rows.length} of{' '}
              {getFilteredRowModel().rows.length}
            </span>
          ) : (
            <span>
              Showing {(current - 1) * pageSize + (getRowModel().rows.length > 0 ? 1 : 0)} to{' '}
              {(current - 1) * pageSize + getRowModel().rows.length} of {totalCount}
            </span>
          )}
        </div>
      </Col>
      {!showPagination && (
        <Col size={4} className="text-right">
          {server !== 'true' ? (
            <Pagination
              totalPages={getPageCount()}
              currentPage={pageIndex + 1}
              siblingCount={1}
              onChange={(val) => setPageIndex(val - 1)}
              showPrev={getCanPreviousPage()}
              showNext={getCanNextPage()}
              nextPage={nextPage}
              previousPage={previousPage}
            />
          ) : (
            <Pagination
              totalPages={getTotalPage()}
              currentPage={current}
              siblingCount={1}
              onChange={(val) => changePage(val)}
              showPrev={true}
              showNext={true}
              nextPage={() => changePagenext('next')}
              previousPage={() => changePagenext('previous')}
            />
          )}
        </Col>
      )}
    </Row>
  );
}

export default PaginationContainer;
