import { default as classNames } from 'classnames';
import { HTMLAttributes } from 'react';

type ContainerProps = HTMLAttributes<HTMLElement> & {
  tag?: keyof HTMLElementTagNameMap;
  fluid?: boolean;
};

export function Container({
  tag: Tag = 'div',
  children,
  className,
  fluid,
  ...props
}: ContainerProps) {
  return (
    <Tag className={classNames({ 'container mx-auto': !fluid }, className)} {...props}>
      {children}
    </Tag>
  );
}
