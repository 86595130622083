import { css } from '@emotion/css';
import classNames from 'classnames';
import { themeConfig } from 'config/themeConfig';
import { get } from 'lodash';
import { HTMLAttributes } from 'react';

type NavItemProps = HTMLAttributes<HTMLElement> & {
  tag?: keyof HTMLElementTagNameMap;
  active?: boolean;
};

const navItemCSS = css({
  fontWeight: 600,
  position: 'relative',
  color: get(themeConfig, 'colors.neutral.400'),
  cursor: 'pointer',
  transition: 'all .2s',

  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: -1,
    left: 0,
    right: 0,
    height: 0,
    width: '80%',
    margin: 'auto',
    backgroundColor: get(themeConfig, 'colors.primary'),
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    transition: 'all .2s',
  },
  '&.active': {
    color: get(themeConfig, 'colors.primary'),
    '&:after': {
      height: 5,
    },
  },
});

function NavItem({ tag: Tag = 'div', className, active, ...props }: NavItemProps) {
  const classes = classNames('py-2 px-4', navItemCSS, { active }, className);
  return <Tag className={classes} {...props} />;
}

export default NavItem;
