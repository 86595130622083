import classNames from 'classnames';
import { HTMLAttributes, useContext } from 'react';
import { TabContext } from './TabContext';

export type TabContentProps = HTMLAttributes<HTMLElement> & {
  tag?: keyof HTMLElementTagNameMap;
  tab: string | number;
  noPadding?: boolean;
};

export default function TabContent({
  tag: Tag = 'div',
  tab,
  noPadding,
  className,
  ...props
}: TabContentProps) {
  const { activeTab } = useContext(TabContext);
  if (activeTab !== tab) return null;

  const classes = classNames(
    {
      'p-4': !noPadding,
    },
    className
  );
  return <Tag className={classes} {...props} />;
}
