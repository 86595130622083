/* eslint @typescript-eslint/no-explicit-any: 0 */
// @ts-nocheck

/* eslint-disable @typescript-eslint/no-unused-vars */
import { css } from '@emotion/css';
import { Column, RowData, Table } from '@tanstack/react-table';
import { Button } from 'components/ui/Button';
import { Card } from 'components/ui/Card';
import { CardBody } from 'components/ui/CardBody';
import { CardHeader } from 'components/ui/CardHeader';
import { Container } from 'components/ui/Container';
import { FormField } from 'components/ui/FormField';
import { FormGroup } from 'components/ui/FormGroup';
import Icon from 'components/ui/Icon';
import { InputProps } from 'components/ui/Input';
import Modal from 'components/ui/Modal';
import RadioGroup from 'components/ui/RadioGroup';
import RadioGroupItem from 'components/ui/RadioGroupItem';
import { Select } from 'components/ui/Select';
import { themeConfig } from 'config/themeConfig';
import { useFormik } from 'formik';
import usePromiseToaster from 'hooks/usePromiseToaster';
import { get } from 'lodash';
import { ReactNode, useCallback, useState } from 'react';
import { MultiValue } from 'react-select';
import { toast } from 'react-toastify';
import AuthService from 'services/AuthService';
import * as yup from 'yup';
import GlobalFilter from './GlobalFilter';

type MailObject = {
  class: string;
  module: string;
};
type TableOptionsComponentProps<TData extends RowData> = {
  tableInstance: Table<TData>;
  globalFilter: string;
  setGlobalFilter: (globalFilter: string) => void;
  prefixOptions?: (tableInstance: Table<TData>) => ReactNode;
  fileName?: string;
  mailObject?: MailObject;
  pinningBtn?: boolean;
  visibilityBtn?: boolean;
  downloadBtn?: boolean;
  mailBtn?: boolean;
  copyBtn?: boolean;
  authBtn?: boolean;
  authClickHandle?: () => void;
};
const alertValidationSchema = yup.object().shape({
  mode: yup.mixed().required(),
  emailAddress: yup
    .string()
    .email('Email is invalid')
    .test('email required', 'Email Address Required', (value, context) => {
      const { mode } = context.parent;
      if (mode === 'email') {
        return value !== undefined;
      }
      return true;
    }),
});
function TableOptionsComponent<TData extends RowData>({
  tableInstance,
  globalFilter,
  setGlobalFilter,
  prefixOptions = () => null,
  fileName,
  mailObject,
  pinningBtn,
  visibilityBtn,
  downloadBtn,
  mailBtn,
  copyBtn,
  authBtn,
  authClickHandle,
}: TableOptionsComponentProps<TData>) {
  const {
    setColumnOrder,
    setColumnVisibility,
    getAllColumns,
    getCoreRowModel,
    getFilteredRowModel,
    getHeaderGroups,
  } = tableInstance;
  const [visbileColumns, setVisbileColumns] = useState<MultiValue<Column<TData>>>(getAllColumns());
  const [columnPinings, setColumnPinings] = useState<MultiValue<Column<TData>>>([]);
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState<InputProps['value']>('download');
  const alertServiceToaster = usePromiseToaster();
  const { handleSubmit, getFieldProps, touched, errors, isSubmitting, setFieldValue, values } =
    useFormik<InitialValuesType>({
      initialValues: {
        mode: 'download',
        emailAddress: '',
      },
      validationSchema: alertValidationSchema,
      onSubmit: async (values, { setSubmitting }) => {
        let columnDefsArray = getAllColumns().map((item) => item.columnDef);

        let result = {
          class: mailObject?.class,
          method: 'exportListMail',
          type: values.mode === 'email' ? '2' : '1',
          module: mailObject?.module,

          field: columnDefsArray
            .map((item) => {
              if (item.id === 'select') {
                return null;
              }
              return {
                field: item.id,
                name: item.header,
              };
            })
            .filter((item) => item !== null),
          filter: {
            searchvalue: globalFilter,
            sortBy: '',
            sortDesc: '',
          },
        };
        if (values.mode === 'email') {
          result = {
            ...result,
            email: values.emailAddress,
          };
        }
        if (mailObject?.filterParams) {
          result.filter = {
            ...result.filter,

            ...mailObject.filterParams,
          };
        }

        alertServiceToaster.loading();
        AuthService.exportList(result).then((res) => {
          if (res.status && res.code === 200) {
            alertServiceToaster.success('Export mail processing');
            exportMail();
          }
        });
        setSubmitting(false);
      },
    });

  const getContent = (jointype) => {
    const headerGroup = getHeaderGroups();

    const header = [];
    headerGroup[0].headers.map((head) => {
      if (
        head.column.columnDef.id !== 'select' &&
        head.column.columnDef.id !== undefined &&
        head.column.columnDef.header?.toString().toLowerCase().trim() !== 'action' &&
        head.column.columnDef.header?.toString().toLowerCase().trim() !== 'actions'
      ) {
        header.push(head);
      }
      return null;
    });
    if (header.length === 0) return false;
    const title = header.map(function (head) {
      return head.column.columnDef.header;
    });
    const rowData = getFilteredRowModel().rows.map((copy) => {
      const row = copy.original;
      const data = header.map((element) => {
        return element.id in row ? row[element.id] : '';
      });
      return data.join(jointype);
    });
    if (rowData.length > 0) {
      const text = title.join(jointype) + '\n' + rowData.join('\n');
      return text;
    } else {
      return false;
    }
  };

  const downloadCSV = useCallback(() => {
    try {
      const downloadData = getContent(',');
      let csvContent = 'data:text/csv;charset=utf-8,' + downloadData;

      var encodedUri = encodeURI(csvContent);

      var link = document.createElement('a');
      link.setAttribute('href', encodedUri);

      let csvFileName =
        fileName || (new Date().getTime() + Math.round(Math.random() * 100000)).toString(36);

      csvFileName = csvFileName.endsWith('.csv') ? csvFileName : `${csvFileName}.csv`;
      link.setAttribute('download', csvFileName);
      document.body.appendChild(link); // Required for FF

      link.click();
      link.remove();
    } catch (err) {
      // console.error(err);
      toast.error('Sorry, something went wrong');
    }
  }, [getCoreRowModel]); // eslint-disable-line

  const copyTable = () => {
    try {
      const copyData = getContent('\t');
      if (copyData) {
        navigator.clipboard.writeText(copyData);
        toast.success(`${copyData.split(/\r?\n|\r|\n/g).length - 1} rows copied`);
      } else {
        toast.error('No Rows Copied');
      }
    } catch (err) {
      // console.error(err);
      toast.error('Sorry, something went wrong');
    }
  };

  const exportMail = () => {
    setOpen(!open);
  };

  return (
    <div className="options md:flex justify-between mb-3">
      <div>{prefixOptions(tableInstance)}</div>
      <div className="md:flex justify-center items-center">
        <div className="w-full">
          <GlobalFilter value={globalFilter} onChange={setGlobalFilter} />
        </div>

        {authBtn && (
          <Button
            color="black"
            outline
            size="sm"
            className="ml-3"
            onClick={authClickHandle}
            title="Authentication"
          >
            <Icon name="user-lock" />
          </Button>
        )}
        {pinningBtn && (
          <div className={`ml-3  ${css({ width: 36, minWidth: 36 })}`} title="Column pinning">
            <Select
              styles={{
                control: (base, props) => ({
                  ...base,
                  cursor: 'pointer',
                  borderColor: get(themeConfig, 'colors.black'),
                  color: get(themeConfig, 'colors.black'),
                  '&:hover': {
                    backgroundColor: get(themeConfig, 'colors.black'),
                    borderColor: get(themeConfig, 'colors.black'),
                    color: '#FFF',
                  },
                }),
                menu: (base) => ({
                  ...base,
                  left: 'auto',
                  right: 0,
                  minWidth: 200,
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: 'none',
                  color: get(themeConfig, 'colors.black'),
                }),
              }}
              isSearchable={false}
              isClearable={false}
              controlShouldRenderValue={false}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                Placeholder: (props) => {
                  return (
                    <div
                      className={css({
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      })}
                    >
                      <Icon name="pin" />
                    </div>
                  );
                },
              }}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              optionWith="checkbox"
              options={getAllColumns().filter(
                (column) =>
                  typeof column?.columnDef?.header === 'string' && !!column.columnDef.header
              )}
              getOptionLabel={(column) =>
                typeof column?.columnDef?.header === 'string' ? column?.columnDef?.header : ''
              }
              getOptionValue={(column) => column.id}
              value={columnPinings}
              onChange={(options) => {
                if (options.length > 3) return;
                options.forEach((col) => {
                  col.pin('left');
                });
                setColumnPinings(options.slice(0, 3));

                const originalColumnIDs = getAllColumns().map((col) => col.id);
                const selectedColumnIDs = options.map((col) => col.id);
                const newColumnPinings = [...selectedColumnIDs, ...originalColumnIDs].filter(
                  (item, inx, arr) => arr.indexOf(item) === inx
                );
                setColumnOrder([...newColumnPinings]);
              }}
            />
          </div>
        )}
        {visibilityBtn && (
          <div className={`ml-3 ${css({ width: 36, minWidth: 36 })}`} title="Column visibility">
            <Select
              styles={{
                control: (base, props) => ({
                  ...base,
                  cursor: 'pointer',
                  borderColor: get(themeConfig, 'colors.black'),
                  color: get(themeConfig, 'colors.black'),
                  '&:hover': {
                    backgroundColor: get(themeConfig, 'colors.black'),
                    borderColor: get(themeConfig, 'colors.black'),
                    color: '#FFF',
                  },
                }),
                menu: (base) => ({
                  ...base,
                  left: 'auto',
                  right: 0,
                  minWidth: 200,
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: 'none',
                  color: get(themeConfig, 'colors.black'),
                }),
              }}
              isSearchable={false}
              isClearable={false}
              controlShouldRenderValue={false}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                Placeholder: () => {
                  return (
                    <div
                      className={css({
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      })}
                    >
                      <Icon name="eye" />
                    </div>
                  );
                },
              }}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              optionWith="checkbox"
              options={getAllColumns().filter(
                (column) =>
                  typeof column?.columnDef?.header === 'string' && !!column.columnDef.header
              )}
              getOptionLabel={(column) =>
                typeof column?.columnDef?.header === 'string' ? column?.columnDef?.header : ''
              }
              getOptionValue={(column) => column.id}
              value={visbileColumns}
              onChange={(options) => {
                setVisbileColumns(options);

                const visbileColumnIDs = options.map((col) => col.id);
                const localVisbileColumns = getAllColumns()
                  .map((col) => col.id)
                  .reduce((final, id) => ({ ...final, [id]: visbileColumnIDs.includes(id) }), {});

                setColumnVisibility(localVisbileColumns);
              }}
            />
          </div>
        )}
        {downloadBtn && (
          <Button
            className={`ml-3 `}
            color="black"
            outline
            size="sm"
            onClick={downloadCSV}
            title="Download"
          >
            <Icon name="download" />
          </Button>
        )}
        {mailBtn && (
          <Button
            size="sm"
            color="black"
            outline
            className="ml-3"
            onClick={exportMail}
            title="Mail"
          >
            <Icon name="mail" />
          </Button>
        )}
        {copyBtn && (
          <Button size="sm" color="black" outline className="ml-3" onClick={copyTable} title="Copy">
            <Icon name="copy" />
          </Button>
        )}
        {open && (
          <>
            <Modal isOpen={open} onClose={() => setOpen(!open)} size="sm" justify="center">
              <Container>
                <Card
                  className=" flex flex-col "
                  tag="form"
                  onSubmit={(e: FormEvent<HTMLFormElement>) => handleSubmit(e)}
                >
                  <CardHeader tag="h5" toggle={() => setOpen(!open)} borderBottom>
                    <div>Export Email</div>
                  </CardHeader>
                  <CardBody className="overflow-auto h-full">
                    <FormGroup>
                      <RadioGroup
                        value={values.mode}
                        onChange={(value) => {
                          // setMode(value);
                          setFieldValue('mode', value);
                        }}
                        error={touched.mode && !!errors.mode}
                        feedbackText={errors.mode}
                        direction="row"
                      >
                        <RadioGroupItem value={'download'} label="Download" />
                        <RadioGroupItem value={'email'} label="Email" />
                      </RadioGroup>
                    </FormGroup>

                    {values.mode === 'email' && (
                      <FormGroup>
                        <FormField
                          label="Mail To"
                          inputProps={{
                            ...getFieldProps('emailAddress'),
                          }}
                          error={touched.emailAddress && !!errors.emailAddress}
                          feedbackText={errors.emailAddress}
                        />
                      </FormGroup>
                    )}
                    <Button color="primary" type="submit" isSubmitting={isSubmitting}>
                      Submit
                    </Button>
                  </CardBody>
                </Card>
              </Container>
            </Modal>
          </>
        )}
      </div>
    </div>
  );
}

export default TableOptionsComponent;
