import { createSlice } from '@reduxjs/toolkit';
import { STORAGE_KEYS } from 'constants/index';
import StorageManager from 'utils/StorageManager';

const authState = StorageManager.getItem(STORAGE_KEYS.AUTH_STATE);

const initialState = {
  userId: '',
  twoFactAuth: '',
  otpDuration: '',
  token: '',
  isAuthenticated: false,
  roleID: -1,
  picture: '',
  firstName: '',
  lastName: '',
  fullname: '',
  merchantID: '',
  role: '',
  merchantName: '',
  email: '',
  userName: '',
  perPage: '',
  siteGroupOption: null,
  sitegroup: null,
  needPasswordChange: false,
  // captcha: '',
  favList: [],
  needChangePass: false
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: authState || initialState,
  reducers: {
    login: (state, action) => {
      return {
        ...state,
        userId: action.payload.data.usr_id,
        needChangePass: action.payload.data.needChangePasswd,
        twoFactAuth: action.payload.data.two_fact_auth,
        otpDuration: action.payload.data.otp_duration,
        token: action.payload.token,
        isAuthenticated: !!action.payload.token,
        roleID: action.payload.data.role_id,
        picture: action.payload.data.picture,
        firstName: action.payload.data.first_name,
        lastName: action.payload.data.last_name,
        fullname: [action.payload.data.first_name, action.payload.data.last_name].join(' '),
        merchantID: action.payload.data.merchant_id,
        role: action.payload.data.role_name,
        merchantName: action.payload.data.merchant_name,
        email: action.payload.data.email,
        userName: action.payload.data.username,
        perPage: action.payload.data.per_page,
        favList: action.payload.data.fav,
        sitegroup: action.payload.data.sitegroup,
      };
    },
    logout: (state, action) => {
      return initialState;
    },
    //siteGroup
    setSiteGroup: (state, action) => {
      state.siteGroupOption = action.payload;
    },
    setFavList: (state, action) => {
      state.favList = action.payload;
    },
  },
});

export const { login, logout, setSiteGroup, setFavList } = authSlice.actions;

export default authSlice;
