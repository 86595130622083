import classNames from 'classnames';
import { FormHTMLAttributes } from 'react';

type FormProps = FormHTMLAttributes<HTMLFormElement> & {
  tag?: 'form';
};

export function Form({ tag: Tag = 'form', className, ...props }: FormProps) {
  const classes = classNames(className);
  return <Tag className={classes} {...props} />;
}
