import classNames from 'classnames';
import { LabelHTMLAttributes } from 'react';

export type LabelProps = Omit<LabelHTMLAttributes<HTMLLabelElement | HTMLElement>, 'size'> & {
  size?: 'sm' | 'md' | 'lg';
  check?: boolean;
};

export function Label({ className, size = 'md', check, ...props }: LabelProps) {
  const spacingClasses = {
    'mb-1 text-xs': size === 'sm',
    'mb-2 text-base': size === 'md',
    'mb-3 text-lg': size === 'lg',
    'items-center cursor-pointer': check,
  };
  const classes = classNames('form-label', spacingClasses, className);
  return <label className={classes} {...props} />;
}
