import { createSlice } from '@reduxjs/toolkit';

// replace you initialState here
const initialState = {
  currentRoute: 'Sample value of reducer',
  breadcrumsMenus: [],
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  // replace you reducers here
  reducers: {
    setCurrentRoute: (state, action) => {
      state.currentRoute = action.payload;
    },
    setBreadcrumsMenus: (state, action) => {
      state.breadcrumsMenus = action.payload;
    },
  },
});

export const { setCurrentRoute, setBreadcrumsMenus } = commonSlice.actions;

export default commonSlice;
