import { css } from '@emotion/css';
import classNames from 'classnames';
import { get } from 'lodash';
import { ReactNode, useEffect } from 'react';
import { useFlexLayout, useGlobalFilter, usePagination, useRowSelect, useTable } from 'react-table';
import { Button } from '../Button';
import { Col } from '../Col';
import Icon from '../Icon';
import { Input } from '../Input';
import { Row } from '../Row';
import { Select } from '../Select';
import Pagination from './Pagination';
import { CellProps, Hooks, TableInstance, TableOptions, Row as TableRow } from './TableTypes';

type OldTableProps<D extends object> = TableOptions<D> & {
  responsive?: boolean;
  rowSelection?: boolean;
  onRowDoubleClick?: (row: TableRow<D>) => void; // eslint-disable-next-line
  onRowSelectionChange?: (selectedRows: Array<TableRow<D>['original']>) => void; // eslint-disable-next-line
  prefixOptions?: (tableInstance: TableInstance<D>) => ReactNode;
  // eslint-disable-next-line
  innerRef?: any;
  showButtons?: boolean;
  showPagination?: boolean;
};

export function OldTable<D extends object>({
  rowSelection,
  responsive,
  showButtons,
  showPagination,
  onRowDoubleClick = () => {},
  onRowSelectionChange = () => {},
  prefixOptions = () => '',
  innerRef,
  ...props
}: OldTableProps<D>) {
  const tableInstance = useTable(
    // @ts-ignore
    {
      ...props,
    },
    useFlexLayout,
    useGlobalFilter,
    usePagination,
    useRowSelect,
    (hooks: Hooks<D>): void => {
      if (rowSelection) {
        hooks.allColumns.push((columns) => [
          {
            id: 'selections',
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <Input type={'checkbox'} {...getToggleAllPageRowsSelectedProps()} />
            ),
            Cell: ({ row }: CellProps<D>) => (
              <Input type={'checkbox'} {...row.getToggleRowSelectedProps()} />
            ),
            width: 100,
          },
          ...columns,
        ]);
      }
    }
  ) as unknown as TableInstance<D>;

  let {
    headerGroups,
    rows,
    page,
    getTableProps,
    getTableBodyProps,
    prepareRow,
    state,
    totalColumnsWidth,

    // useRowSelect
    selectedFlatRows,

    // useGlobalFilter
    preGlobalFilteredRows,
    setGlobalFilter,

    //usePagination
    canPreviousPage,
    canNextPage,
    // pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = tableInstance;

  const { pageIndex, pageSize, selectedRowIds } = state;

  useEffect(() => {
    const selectedRows = selectedFlatRows.map((item) => item.original);
    onRowSelectionChange(selectedRows);
  }, [selectedRowIds]); // eslint-disable-line
  return (
    <>
      {/* <code>
        {JSON.stringify(
          {
            pageIndex,
            pageSize,
            pageCount,
            canNextPage,
            canPreviousPage,
          },
          null,
          2
        )}
      </code> */}
      {showButtons && (
        <div className="options md:flex justify-between">
          {/* <div className="w-full"></div> */}
          <div>{prefixOptions(tableInstance)}</div>
          <div className="md:flex ">
            <div className="w-full">
              <Input
                className={css({ maxWidth: 400, minWidth: 254 })}
                value={state.globalFilter || ''}
                onChange={(e) => {
                  setGlobalFilter(e.target.value);
                }}
                placeholder={`${preGlobalFilteredRows.length} rows`}
              />
            </div>
            <Button color="black" outline className="ml-3">
              <Icon name="pin" />
            </Button>
            <Button color="black" outline className="ml-3">
              <Icon name="eye" />
            </Button>
            <Button color="black" outline className="ml-3">
              <Icon name="download" />
            </Button>
            <Button color="black" outline className="ml-3">
              <Icon name="mail" />
            </Button>
            <Button color="black" outline className="ml-3">
              <Icon name="copy" />
            </Button>
          </div>
        </div>
      )}
      <div className={classNames({ 'overflow-auto': responsive })}>
        <div className="react-table" {...getTableProps({ style: { width: totalColumnsWidth } })}>
          <div className="thead" style={{ width: totalColumnsWidth }}>
            {headerGroups.map((headerGroup) => {
              const { getHeaderGroupProps, headers } = headerGroup;
              return (
                <div
                  className="tr"
                  {...getHeaderGroupProps({ style: { width: totalColumnsWidth } })}
                >
                  {headers.map((column) => {
                    return (
                      <div
                        className="th py-4 px-2  text-left"
                        // style={{ width, maxWidth, minWidth }}
                        {...column.getHeaderProps()}
                      >
                        {column.render('Header')}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div className="tbody" {...getTableBodyProps({ style: { width: totalColumnsWidth } })}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <div
                  {...row.getRowProps({ style: { width: totalColumnsWidth } })}
                  onDoubleClick={() => onRowDoubleClick(row)}
                  className={'tr'}
                >
                  {row.cells.map((cell) => {
                    return (
                      <div className="td px-2 font text-sm" {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {showPagination && (
        <Row>
          <Col size={4}>
            <div className="flex">
              <Select
                menuPlacement="auto"
                value={{ label: pageSize, value: pageSize }}
                options={[10, 25, 50, 100, 500, 1000, 2000, 5000].map((i) => ({
                  label: i,
                  value: i,
                }))}
                onChange={(option) => {
                  setPageSize(get(option, 'value', 0));
                }}
              />
            </div>
          </Col>
          <Col size={4} className="text-center">
            <div>
              Showing {pageIndex * pageSize + 1} to {pageIndex * pageSize + page.length} of{' '}
              {rows.length}
            </div>
          </Col>
          <Col size={4} className="text-right">
            <Pagination
              totalPages={pageCount}
              currentPage={pageIndex + 1}
              siblingCount={1}
              onChange={(val) => gotoPage(val - 1)}
              showPrev={canPreviousPage}
              showNext={canNextPage}
              nextPage={nextPage}
              previousPage={previousPage}
            />
          </Col>
        </Row>
      )}
    </>
  );
}
