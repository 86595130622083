import { ReactNode } from 'react';
import { Input, InputProps } from './Input';
import { Label, LabelProps } from './Label';

type CheckBoxProps = InputProps & {
  children?: ReactNode;
  label?: string;
  labelProps?: LabelProps;
};

export default function CheckBox({
  children,
  labelProps,
  label,
  type = 'checkbox',
  ...props
}: CheckBoxProps) {
  return (
    <Label check {...labelProps}>
      <Input type={type} {...props} /> {children || label}
    </Label>
  );
}
