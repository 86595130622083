import { css } from '@emotion/css';
import classNames from 'classnames';
import { GroupBase } from 'react-select';
import CheckBox from './CheckBox';
import { FormFeedback } from './FormFeedback';
import { FormGroup } from './FormGroup';
import { Input, InputProps } from './Input';
import { Label } from './Label';
import Radio from './Radio';
import RangeSlider, { RangeSliderProps } from './RangeSlider';
import { Select, SelectProps } from './Select';

const foldingCSS = css({
  position: 'relative',
  paddingTop: 24 / 2,
  '.form-label': {
    margin: 0,
    position: 'absolute',
    zIndex: 1,
    padding: '2px 4px',
    top: 0,
    left: 8,
    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: '#FFF',
      height: 3,
      width: '100%',
      margin: 'auto',
      zIndex: -1,
    },
  },
});

type FormFieldProps<
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
> = {
  type?: InputProps['type'] | 'react-select' | 'range-slider';
  error?: boolean;
  feedbackText?: string;
  label?: string;
  size?: 'sm' | 'md' | 'lg';
  inputProps?: InputProps;
  selectProps?: SelectProps<Option, IsMulti, Group>;
  rangeSliderProps?: RangeSliderProps;
  folding?: boolean;
};

export function FormField<
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  error,
  feedbackText,
  label,
  size,
  type,
  inputProps,
  selectProps,
  rangeSliderProps,
  folding,
  ...props
}: FormFieldProps<Option, IsMulti, Group>) {
  const getField = () => {
    if (type === 'react-select') {
      return <Select invalid={error} {...selectProps} />;
    }
    if (type === 'checkbox') {
      return <CheckBox type={type} invalid={error} size={size} {...inputProps} label={label} />;
    }
    if (type === 'radio') {
      return <Radio type={type} invalid={error} size={size} {...inputProps} label={label} />;
    }
    if (type === 'range-slider') {
      return <RangeSlider {...rangeSliderProps} />;
    }
    return <Input type={type} invalid={error} size={size} {...inputProps} />;
  };

  return (
    <FormGroup className={classNames({ [foldingCSS]: folding })}>
      {type !== 'checkbox' && type !== 'radio' && label && <Label size={size}>{label}</Label>}
      {getField()}
      {error && <FormFeedback invalid={error}>{feedbackText}</FormFeedback>}
    </FormGroup>
  );
}
