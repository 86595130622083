import { useRef } from 'react';
import { Id, toast, ToastOptions } from 'react-toastify';

type usePromiseToasterOptions = ToastOptions;

export default function usePromiseToaster(params?: usePromiseToasterOptions) {
  const {
    isLoading = false,
    autoClose = 5000,
    closeButton = true,
    pauseOnFocusLoss = true,
    ...options
  } = params || {};
  const toastId = useRef<Id>(-1);

  const loading = (message: string = 'Please wait..') => (toastId.current = toast.loading(message));

  const success = (message: string = 'Done!') =>
    toast.update(toastId.current, {
      ...options,
      isLoading,
      autoClose,
      closeButton,
      pauseOnFocusLoss,
      render: message,
      type: 'success',
    });

  const error = (message: string = 'Something went wrong') =>
    toast.update(toastId.current, {
      ...options,
      isLoading,
      autoClose,
      closeButton,
      pauseOnFocusLoss,
      render: message,
      type: 'error',
    });

  const dismiss = () => toast.dismiss(toastId.current);

  return {
    loading,
    success,
    error,
    dismiss,
  };
}
