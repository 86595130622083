import { css } from '@emotion/css';
import { Placement } from '@popperjs/core';
import classNames from 'classnames';
import { themeConfig } from 'config/themeConfig';
import { get } from 'lodash';
import { CSSProperties, HTMLAttributes, useEffect, useState } from 'react';
import { usePopper } from 'react-popper';

const favouritesCSS = {
  poperOverWrapper: css({
    maxWidth: 300,
    padding: 16,
    backgroundColor: 'white',
    borderRadius: get(themeConfig?.borderRadius, 'md'),
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: get(themeConfig?.borderColor, 'neutral.200'),
  }),
};

type TooltipProps = HTMLAttributes<HTMLElement> & {
  target: string;
  placement?: Placement;
};

const positions = {
  top: 'bottom',
  bottom: 'top',
  left: 'right',
  right: 'left',
  end: 'right',
};
const rotates = {
  top: 'rotate(-135deg)',
  bottom: 'rotate(45deg)',
  left: 'rotate(135deg)',
  right: 'rotate(-45deg)',
};
const getTooltipSpace = (placement: string = '') => {
  const [position, secondaryPosition]: string[] = placement.split('-');
  if (position === 'top' || position === 'bottom' || position === 'left' || position === 'right') {
    const styles: CSSProperties = {
      [positions[position]]: 12,
    };
    if (secondaryPosition && ['top', 'bottom'].includes(position)) {
      styles[secondaryPosition === 'end' ? 'right' : 'left'] = -12;
    }
    if (secondaryPosition && ['left', 'right'].includes(position)) {
      styles[secondaryPosition === 'end' ? 'bottom' : 'top'] = -12;
    }
    return styles;
  }
  return {};
};
const getArrowStyle = (placement: string = '') => {
  const [position, secondaryPosition]: string[] = placement.split('-');
  const styles: CSSProperties = {};
  if (!secondaryPosition) {
    if (position === 'top' || position === 'bottom') {
      styles['left'] = 0;
      styles['right'] = 0;
      styles['margin'] = 'auto';
    }
    if (position === 'left' || position === 'right') {
      styles['top'] = 0;
      styles['bottom'] = 0;
      styles['margin'] = 'auto';
    }
  } else {
    if (secondaryPosition && ['top', 'bottom'].includes(position)) {
      styles[secondaryPosition === 'end' ? 'right' : 'left'] = 12;
      styles[secondaryPosition === 'end' ? 'left' : 'right'] = '';
    }
    if (secondaryPosition && ['left', 'right'].includes(position)) {
      styles[secondaryPosition === 'end' ? 'bottom' : 'top'] = 12;
      styles[secondaryPosition === 'end' ? 'top' : 'bottom'] = '';
    }
  }

  if (position === 'top' || position === 'bottom' || position === 'left' || position === 'right') {
    // @ts-ignore
    styles[positions[position]] = -7;
    styles['transform'] = rotates[position];
  }
  return styles;
};

function Tooltip({ children, target, placement: placementProp = 'auto' }: TooltipProps) {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes, state } = usePopper(document.getElementById(target), popperElement, {
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowElement,
        },
      },
    ],
    placement: placementProp,
  });

  const { placement } = state || {};

  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    document.getElementById(target)?.addEventListener('mouseover', () => setIsOpen(true));
    document.getElementById(target)?.addEventListener('mouseleave', () => setIsOpen(false));
    return () => {
      document.getElementById(target)?.removeEventListener('mouseover', () => {});
      document.getElementById(target)?.removeEventListener('mouseleave', () => {});
    };
  }, []); // eslint-disable-line

  if (!isOpen) return null;

  return (
    <div
      ref={setPopperElement}
      className={classNames(favouritesCSS.poperOverWrapper, 'shadow-lg')}
      style={{ ...styles.popper, zIndex: 1, ...getTooltipSpace(placement) }}
      {...attributes.popper}
    >
      <div
        ref={setArrowElement}
        className={'border-t border-l border-t-neutral-200 border-l-neutral-200'}
        style={{
          ...styles.arrow,
          height: 13,
          width: 13,
          borderTopLeftRadius: 2,
          backgroundColor: 'white',

          ...getArrowStyle(placement),
        }}
      />
      {children}
    </div>
  );
}

export default Tooltip;
