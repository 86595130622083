import classNames from 'classnames';
import { ButtonHTMLAttributes } from 'react';
import { cx } from 'utils/functions';
import { ThemeColors, ThemeSizes } from 'utils/types';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement | HTMLElement> & {
  tag?: 'button' | 'div';
  color?: ThemeColors;
  size?: 'sm' | 'md' | 'lg';
  rounded?: ThemeSizes | 'full' | boolean;
  shadow?: ThemeSizes | boolean;
  block?: boolean;
  outline?: boolean;
};

export function Button({
  tag: Tag = 'button',
  children,
  className,
  size = 'md',
  color = 'primary',
  rounded = 'md',
  shadow = 'sm',
  block = false,
  outline = false,
  ...props
}: ButtonProps) {
  const roundedClasses = {
    [cx('rounded', rounded)]: rounded,
  };
  const colorClasses = {
    [cx('bg', color)]: !outline && color,
    [cx('hover:bg', color)]: outline,
    'hover:text-white': outline,
    [cx('border', color)]: color,
  };
  const spacingClasses = {
    'px-3 py-2 text-xs': size === 'sm',
    'px-6 py-2 text-base': size === 'md',
    'px-8 py-3 text-lg': size === 'lg',
  };
  const buttonClasses = {
    'w-full': block,
  };
  const classes = classNames(
    'btn',
    outline ? cx('text', color) : 'text-white',
    colorClasses,
    spacingClasses,
    roundedClasses,
    buttonClasses,
    className
  );

  return (
    <Tag className={classes} {...props}>
      {children}
    </Tag>
  );
}
