import Layout from 'components/layout';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './assets/styles/app.scss';
import store from './store';

function App() {
  return (
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <BrowserRouter>
          <Layout />
          <ToastContainer closeButton closeOnClick pauseOnHover theme="colored" />
        </BrowserRouter>
      </DndProvider>
    </Provider>
  );
}

export default App;
