import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { STATUS_CODES } from 'constants/serviceConstants';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import store from 'store';
import { logout } from 'store/reducers/authSlice';
import { history } from 'utils/functions';
import { API_BASE_URL } from '../constants';

const APISerive = axios.create({
  baseURL: API_BASE_URL,
  // @ts-ignore
  commonErrorHandling: true,
});

const handleBeforeRequest = (config: AxiosRequestConfig) => {
  const state = store.getState();
  const token = state.auth.token;
  config.headers = {
    ...config.headers,
    Authorization: token,
  };
  return config;
};

const handleRequestError = (error: Error) => {};

APISerive.interceptors.request.use(handleBeforeRequest, handleRequestError);

const handleResponse = (response: AxiosResponse) => {
  let { data: responseData, config } = response;

  if (!responseData.status) {
    // mandatory checks
    if (responseData.code === STATUS_CODES.UNAUTHORIZED) {
      toast.info(responseData.message);
      store.dispatch(logout(''));
      throw new Error(responseData.message);
    }
    // common err handling
    if (get(config, 'commonErrorHandling')) {
      if (responseData.message === 'Invalid_Permission') {
        toast.error("You don't have a Sufficient Privilage for this Page");
        // navigate(1)
        history.navigate(-1);

        throw new Error("You don't have a Sufficient Privilage for this Page");
      } else {
        toast.error(responseData.message);
        throw new Error(responseData.message);
      }
    }
  }

  return responseData;
};

const handleResponseError = (error: Error) => {};

APISerive.interceptors.response.use(handleResponse, handleResponseError);

export default APISerive;
