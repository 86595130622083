import { configureStore, Middleware, Store } from '@reduxjs/toolkit';
import { STORAGE_KEYS } from 'constants/index';
import { Reducer } from 'redux';
import StorageManager from 'utils/StorageManager';
import rootReducer from './reducers/rootReducer';

// Types
export type AsyncReducers = {
  [key: string]: Reducer;
};

type EnhancedStore = Store & {
  asyncReducers?: AsyncReducers;
};

const middlewares: Middleware[] = [];

const store: EnhancedStore = configureStore({
  reducer: rootReducer(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(middlewares),
  devTools: process.env.NODE_ENV === 'development',
});

store.asyncReducers = {};

export const injectReducer = (key: string, reducer: Reducer) => {
  if (!key) return;

  if (store.asyncReducers?.key) {
    return false;
  }
  store.asyncReducers = {
    ...store.asyncReducers,
    [key]: reducer,
  };
  store.replaceReducer(rootReducer(store.asyncReducers));

  return store;
};

// @ts-ignore
let currntAuthState;

const handleStoreChange = () => {
  const state = store.getState();
  let nextAuthState = state.auth;
  // @ts-ignore
  if (currntAuthState !== nextAuthState) {
    currntAuthState = nextAuthState;
    StorageManager.setItems(STORAGE_KEYS.AUTH_STATE, nextAuthState);
  }
};

store.subscribe(handleStoreChange);
handleStoreChange();

export type RootState = ReturnType<typeof store.getState>;

export default store;
