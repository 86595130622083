import classNames from 'classnames';
import { get } from 'lodash';
import { ComponentType, FC, ReactNode, useMemo } from 'react';
import ReactSelect, {
  GetOptionLabel,
  GetOptionValue,
  GroupBase,
  OptionProps,
  OptionsOrGroups,
  StylesConfig,
  ThemeConfig,
  components,
} from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import { ThemeSizes } from 'utils/types';
import { themeConfig } from '../../config/themeConfig';
import { Input } from './Input';

// console.log('themeConfig', themeConfig);

const primaryColor = get(themeConfig, 'colors.primary', '#56b700');
const successColor = get(themeConfig, 'colors.success');
const dangerColor = get(themeConfig, 'colors.danger');

const defaultBorderColor = get(themeConfig, 'colors.gray[200]', '#e5e7eb');

export type OptionType = {
  [key: string]: any; // eslint-disable-line
};

export type SelectProps<
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
> = StateManagerProps<Option, IsMulti, Group> & {
  border?: boolean;
  rounded?: ThemeSizes | 'full' | boolean;
  shadow?: ThemeSizes | boolean;
  labelKey?: string;
  valueKey?: string;
  optionWith?: 'checkbox' | 'radio';
  children?: ReactNode;
  valid?: boolean;
  invalid?: boolean;
  borderColor?: string;
};

const getThemeConfig: ThemeConfig = (theme) => {
  theme.borderRadius = 6;
  theme.colors = {
    ...theme.colors,
    primary: primaryColor,
    primary75: `${primaryColor}bf`,
    primary50: `${primaryColor}80`,
    primary25: `${primaryColor}40`,
  };

  return theme;
};

const { Option: OptionComponent } = components;

const mapToOptions = (childrens: ReactNode): OptionType[] => {
  if (Array.isArray(childrens)) {
    return childrens.map((item) => ({ label: item.props.children, value: item.props.value }));
  }
  return [];
};

export function Select<
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  border = true,
  rounded = 'md',
  shadow = false,
  labelKey = 'label',
  valueKey = 'value',
  getOptionLabel,
  getOptionValue,
  formatOptionLabel,
  components,
  optionWith,
  children,
  options,
  valid,
  invalid,
  borderColor = defaultBorderColor,
  ...props
}: SelectProps<Option, IsMulti, Group>) {
  const optionsProp = useMemo(() => {
    if (options) return options;

    if (children) {
      if (Array.isArray(children)) {
        return mapToOptions(children);
      } else {
        return mapToOptions([children]);
      }
    }

    return options;
  }, [options, children]) as OptionsOrGroups<Option, Group>;

  const getOptionLabelProp: GetOptionLabel<Option> = useMemo(() => {
    if (getOptionLabel) return getOptionLabel;
    return (option) => `${get(option, labelKey)}`;
  }, [getOptionLabel, labelKey]);

  const getOptionValueProp = useMemo(() => {
    if (getOptionValue) return getOptionValue;
    return (option) => `${get(option, valueKey)}`;
  }, [getOptionValue, valueKey]) as GetOptionValue<Option>;

  const styles: StylesConfig<Option, IsMulti, Group> = useMemo(() => {
    const controlBorderColor = classNames({
      [borderColor]: !valid && !invalid && border,
      transparent: !border,
      [successColor]: valid,
      [dangerColor]: invalid,
    });

    return {
      control: (base, props) => ({
        ...base,
        '&:hover': {
          borderColor: controlBorderColor,
        },
        boxShadow: get(themeConfig, `boxShadow.${shadow || 'none'}`),
        borderColor: controlBorderColor,
        borderRadius: get(themeConfig, `borderRadius.${rounded || 'none'}`),
      }),
      singleValue: (base, props) => ({
        ...base,
        fontSize: get(themeConfig, 'fontSize.base[0]'),
      }),
      indicatorSeparator: (base, props) => ({
        ...base,
        display: 'none',
      }),
      dropdownIndicator: (base, props) => ({
        ...base,
        color: primaryColor,
      }),
      menu: (base, props) => ({
        ...base,
        boxShadow: get(themeConfig, 'boxShadow.lg'),
        zIndex: 999,
      }),
      menuList: (base, props) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
      }),
    };
  }, [border, invalid, valid, rounded, shadow, borderColor]);

  const ReactSelectOption = useMemo(() => {
    if (optionWith) {
      const CustonOptionComponent: ComponentType<OptionProps<OptionType>> = ({
        children,
        isSelected,
        ...props
      }) => {
        return (
          <OptionComponent isSelected={isSelected} {...props}>
            <div className="flex items-center">
              <Input
                type={optionWith}
                className="mr-1 border-slate-400"
                color="primary"
                checked={isSelected}
                readOnly
              />
              {children}
            </div>
          </OptionComponent>
        );
      };
      return CustonOptionComponent;
    }
    return OptionComponent;
  }, [optionWith]) as ComponentType<OptionProps<Option, IsMulti, Group>>;

  return (
    <ReactSelect
      options={optionsProp}
      getOptionLabel={getOptionLabelProp}
      getOptionValue={getOptionValueProp}
      styles={styles}
      theme={getThemeConfig}
      components={{
        Option: ReactSelectOption,
        ...components,
      }}
      {...props}
    />
  );
}

type SelectOptionType = {
  value?: string | number;
  children?: string;
};

export const Option: FC<SelectOptionType> = ({ value, ...props }) => {
  return null;
};
