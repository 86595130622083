import { RuleGroupType } from 'react-querybuilder';
import { NavigateFunction } from 'react-router-dom';
import { Time } from 'services/AlertsServiceTypes';
import { PUBLIC_URL } from '../constants';

// eslint-disable-next-line
type CXS = any;

export const cx = (...cxs: CXS[]) => {
  return cxs.filter((f) => ['string', 'number'].includes(typeof f)).join('-');
};

export const getAssetURL = (url: string) => PUBLIC_URL + url;
// export const getCaptcha = (key: string) => SITE_KEY1 + key
type defaultPermissionType = {
  All: boolean;
};

export function getPermission<permissionType extends defaultPermissionType>(
  permission?: permissionType,
  ...access: Array<keyof permissionType>
) {
  if (permission === undefined) {
    return false;
  }

  if (Object.keys(permission).length === 0) {
    return true;
  }

  if (permission?.All) {
    return true;
  }

  if (!!access.length) {
    return access.some((item) => permission[item]);
  }

  return false;
}

export function randomBetween(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function hexToRgb(hex: string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? 'rgb(' +
        parseInt(result[1], 16) +
        ',' +
        parseInt(result[2], 16) +
        ',' +
        parseInt(result[3], 16) +
        ',0.3)'
    : '';
}

type RuleType = {
  rule: string;
  selectedOperator: string;
  selectedOperand: string;
  value: string;
  type: string;
  input: string;
  field: string;
  operator: string;
  id: string;
};

type QueryType = {
  condition: string;
  rules?: Array<QueryType | RuleType>;
};

type OperandsType = {
  [key: string]: {
    [key: string]: string;
  };
};

export const convertRQtoQuery = (obj: RuleGroupType, operands: OperandsType) => {
  const result: QueryType = {
    condition: obj.combinator.toUpperCase(),
  };

  if ('rules' in obj) {
    result.rules = [];
    for (const item of obj.rules) {
      if ('rules' in item) {
        const rule = convertRQtoQuery(item, operands);
        result.rules.push(rule);
      } else {
        const operand = operands[item.field];
        const rule = {
          rule: item.field,
          selectedOperator: item.operator,
          selectedOperand: operand['label'],
          value: item.value,
          type: 'string',
          input: operand['inputType'],
          field: item.field,
          operator: item.operator,
          id: item.field,
        };
        result.rules.push(rule);
      }
    }
  }
  return result;
};

export const convertQueryToRQ = (obj: QueryType, timeArray?: Time[]) => {
  let result: RuleGroupType = {
    combinator: obj.condition === 'AND' ? 'and' : 'or',
    rules: [],
  };
  if (obj.rules) {
    for (const item of obj.rules) {
      if ('rules' in item) {
        const rule = convertQueryToRQ(item);
        result.rules.push(rule);
      }
      if ('rule' in item) {
        let operator = item.operator || '';
        operator = operator.replace(/_/g, ' ');
        let convertTime = timeArray?.map((item) => ({ name: item.id, label: item.text }));
        let selectedValue = convertTime?.find((i) => i.name === item.value);
        const rule = {
          id: (new Date().getTime() + Math.round(Math.random() * 100)).toString(36),
          field: item.field,
          operator: operator,
          valueSource: 'value',
          value: item.input === 'select' ? selectedValue?.name : item.value,
        };
        // @ts-ignore
        result.rules.push(rule);
      }
    }
  }
  // console.log('tempRes', tempRes);
  return result;
};

export const history: { navigate: NavigateFunction } = {
  navigate: () => {},
};
