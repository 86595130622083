import classNames from 'classnames';
import { BaseHTMLAttributes } from 'react';

import { cx } from 'utils/functions';
import { ThemeColors } from 'utils/types';

export type BadgeProps = BaseHTMLAttributes<HTMLButtonElement | HTMLElement> & {
  tag?: 'span' | 'div';
  color?: ThemeColors;
  size?: 'sm' | 'md' | 'lg';

  pill?: boolean;
};
export function Badge({
  tag: Tag = 'span',
  children,
  className,
  size = 'sm',
  color = 'primary',
  pill = false,
  ...props
}: BadgeProps) {
  const colorClasses = {
    [cx('bg', color)]: color,
  };
  const spacingClasses = {
    'px-2 py-px text-xs': size === 'sm',
    'px-6 py-2 text-base': size === 'md',
    'px-8 py-3 text-lg': size === 'lg',
  };

  const classes = classNames(
    colorClasses,
    spacingClasses,
    'text-white rounded-full inline-block  ',
    className
  );
  return (
    <Tag className={classes} {...props}>
      {children}
    </Tag>
  );
}
