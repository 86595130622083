import { decrypt, encrypt } from './crypto';

const StorageManager = {
  // eslint-disable-next-line
  setItems: (key: string, value: any) => {
    const capsule = { key, value };
    const encryptedKey = encrypt(key);
    const encryptedValue = encrypt(JSON.stringify(capsule));
    localStorage.setItem(encryptedKey, encryptedValue);
  },
  getItem: (key: string) => {
    const encryptedKey = encrypt(key);
    const encryptedCapsuleString = localStorage.getItem(encryptedKey);
    const capsuleString = decrypt(encryptedCapsuleString || '');
    const capsule = JSON.parse(capsuleString || '{}');
    const { value = null } = capsule || {};
    return value;
  },
  removeItem: (key: string) => {
    localStorage.removeItem(key);
  },
  clear: localStorage.clear,
};

export default StorageManager;
