import { default as classNames } from 'classnames';
import { HTMLAttributes } from 'react';
import { cx } from 'utils/functions';
import { ThemeSizes } from 'utils/types';

type CardProps = HTMLAttributes<HTMLElement> & {
  tag?: keyof HTMLElementTagNameMap;
  border?: boolean;
  rounded?: ThemeSizes | 'full' | boolean;
  shadow?: ThemeSizes | boolean;
  body?: boolean;
};

export function Card({
  tag: Tag = 'div',
  children,
  className,
  border,
  shadow = 'lg',
  rounded = 'lg',
  body,
  ...props
}: CardProps) {
  const cardClasses = {
    border: border,
    [cx('shadow', shadow)]: shadow,
    [cx('rounded', rounded)]: rounded,
    'p-4': body,
  };
  const classes = classNames('bg-white', cardClasses, className);
  return (
    <Tag className={classes} {...props}>
      {children}
    </Tag>
  );
}
