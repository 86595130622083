import { default as classNames } from 'classnames';
import { HTMLAttributes } from 'react';
import { cx } from 'utils/functions';

type CardHeaderProps = HTMLAttributes<HTMLElement> & {
  tag?: keyof HTMLElementTagNameMap;
  px?: number;
  py?: number;
  noPadding?: boolean;
  borderBottom?: boolean;
  toggle?: () => void;
};

export function CardHeader({
  tag: Tag = 'div',
  children,
  className,
  py = 2,
  px = 4,
  noPadding,
  borderBottom,
  toggle,
  ...props
}: CardHeaderProps) {
  const cardHeaderClasses = {
    [cx('px', px)]: !noPadding && px,
    [cx('py', py)]: !noPadding && py,
    'border-b': borderBottom,
    'flex items-center justify-between': !!toggle,
  };
  const classes = classNames(cardHeaderClasses, className);
  return (
    <Tag className={classes} {...props}>
      {children}
      {!!toggle && (
        <div onClick={toggle} className="close-button">
          <i className="icon-times"></i>
        </div>
      )}
    </Tag>
  );
}
