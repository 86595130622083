import { css } from '@emotion/css';
import { Input } from 'components/ui/Input';

type GlobalFilterProps = {
  value: string;
  onChange: (value: string) => void;
};

const GlobalFilter = ({ value, onChange }: GlobalFilterProps) => {
  return (
    <Input
      className={css({ maxWidth: 400, minWidth: 254 })}
      value={value || ''}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      placeholder={`Search..`}
    />
  );
};

export default GlobalFilter;
