import { css } from '@emotion/css';
import { Spinner } from 'components/ui';

const pageLoaderCSS = {
  wrapper: css({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  loader: css({}),
};

function PageLoader() {
  return (
    <div className={pageLoaderCSS.wrapper}>
      <Spinner />
    </div>
  );
}

export default PageLoader;
