import { css } from '@emotion/css';
import {
  Column,
  ColumnOrderState,
  Row,
  RowData,
  SortingState,
  TableOptions,
  Table as TableType,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';
import { ReactNode, useEffect, useState } from 'react';
import { Input } from '../Input';
import DraggableColumnHeader from './components/DraggableColumnHeader';
import PaginationContainer from './components/PaginationContainer';
import TableOptionsComponent from './components/TableOptionsComponent';

type FilterParams = {
  [key: string]: string | number;
};
type MailObject = {
  class: string;
  module: string;
  filterParams?: FilterParams;
};

type TableProps<TData extends RowData> = Omit<TableOptions<TData>, 'getCoreRowModel'> & {
  responsive?: boolean;
  enableRowSelection?: boolean;
  showButtons?: boolean;
  showPagination?: boolean;
  showRecordperPage?: boolean;
  enableRowCount?: boolean;
  pagination?: boolean;
  fileName?: string;
  enableColumnDnD?: boolean;
  server?: string;
  totalCount?: number;
  mailObject?: MailObject;
  onRowDoubleClick?: (row: Row<TData>) => void;
  onRowSelectionChange?: (selectedRows: Array<Row<TData>['original']>) => void;
  prefixOptions?: (tableInstance: TableType<TData>) => ReactNode;
  getData?: (page: number, type: string, searchstring: string, sorting: {}) => void;
  pinningBtn?: boolean;
  visibilityBtn?: boolean;
  downloadBtn?: boolean;
  mailBtn?: boolean;
  copyBtn?: boolean;
  authBtn?: boolean;
  authClickHandle?: () => void;
  tableHeight?: number;
};

export function getCommonPinningStyles<TData extends RowData, TValue>(
  column: Column<TData, TValue>,
  left: number
) {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn = isPinned === 'left' ? true : false;
  const isFirstRightPinnedColumn = isPinned === 'right' ? true : false;

  return {
    boxShadow: isLastLeftPinnedColumn
      ? '-4px 0 4px -4px gray inset'
      : isFirstRightPinnedColumn
      ? '4px 0 4px -4px gray inset'
      : undefined,
    left: isPinned === 'left' ? `${column.getStart('left') + left}px` : undefined,
    // right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    position: isPinned ? 'absolute' : undefined,
    width: column.getSize(),
    zIndex: isPinned ? 1 : 0,
    borderBottom: isPinned === 'left' ? `${column.getStart('left') + left}px` : undefined,
  };
}

export function Table<TData extends RowData>({
  responsive,
  enableRowSelection = true,
  showButtons,
  showPagination,
  showRecordperPage = true,
  enableRowCount = true,
  pagination,
  fileName,
  columns,
  server,
  totalCount,
  mailObject,
  enableColumnDnD = true,
  onRowDoubleClick = () => {},
  getData = () => {},
  onRowSelectionChange = () => {},
  prefixOptions = () => null,
  pinningBtn = true,
  visibilityBtn = true,
  downloadBtn = true,
  mailBtn = true,
  copyBtn = true,
  authBtn = false,
  authClickHandle,
  tableHeight,
  ...props
}: TableProps<TData>) {
  const [scrollLeft, setScrollLeft] = useState(0);
  columns = [
    {
      id: 'select',
      enableSorting: false,
      enableResizing: false,
      size: 40,
      header: ({ table }) => (
        <Input
          {...{
            type: 'checkbox',
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <Input
          {...{
            name: `checkbox-${row.id}`,
            type: 'checkbox',
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
    },
    ...columns,
  ];

  // Global Filter State
  const [globalFilter, setGlobalFilter] = useState('');

  // Row Selection
  const [rowSelection, setRowSelection] = useState({});

  // Sorting State
  const [sorting, setSorting] = useState<SortingState>([]);

  // column Visibility
  const [columnVisibility, setColumnVisibility] = useState({});
  // column Pining and DND
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(
    columns.map((col) => col.id as string)
  );

  // Disable Row Selection
  if (!enableRowSelection) {
    columns.splice(0, 1);
  }

  const tableInstance = useReactTable({
    columns,
    ...props,

    // State
    state: {
      globalFilter,
      rowSelection,
      sorting,
      columnVisibility,
      columnOrder,
    },

    // Enableing Row Selection
    enableGlobalFilter: server !== 'true',
    enableRowSelection: true,

    // Column Resizeing
    columnResizeMode: 'onChange',

    getCoreRowModel: getCoreRowModel(),

    // Init Functions
    onGlobalFilterChange: setGlobalFilter,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,

    // Pipeline
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),

    // debug
    debugTable: false,
  });

  const {
    getTotalSize,
    getHeaderGroups,
    getRowModel,
    getSelectedRowModel,
    getCoreRowModel: getCoreRowModelInstance,
  } = tableInstance;

  useEffect(() => {
    const { rows } = getSelectedRowModel();
    onRowSelectionChange(rows.map((row) => row.original));
  }, [rowSelection]); // eslint-disable-line

  return (
    <>
      {showButtons && (
        <TableOptionsComponent
          {...{
            tableInstance,
            globalFilter,
            setGlobalFilter,
            fileName,
            prefixOptions,
            mailObject,
            pinningBtn,
            visibilityBtn,
            downloadBtn,
            mailBtn,
            copyBtn,
            authBtn,
            authClickHandle,
          }}
        />
      )}

      <div
        className={classNames({ 'overflow-auto': responsive })}
        onScroll={(e) => {
          // @ts-expect-error
          setScrollLeft(e.target.scrollLeft);
        }}
      >
        <div
          className={classNames(
            'react-table new-table',
            tableClass(responsive ? '100%' : getTotalSize()),
            css({
              height: tableHeight || 425,
              maxHeight: tableHeight || '100vh',
              minHeight: tableHeight || 425,
            })
          )}
          data-role="table"
        >
          <div
            className={classNames('thead', css({ width: responsive ? '100%' : getTotalSize() }))}
            data-role="thead"
          >
            {getHeaderGroups().map((headerGroup) => {
              const { id: headerGroupID, headers } = headerGroup;
              return (
                <div
                  className={classNames('tr', css({ width: responsive ? '100%' : getTotalSize() }))}
                  data-role="tr"
                  key={headerGroupID}
                >
                  {headers.map((header) => {
                    const { id: headerID } = header;
                    return (
                      // <div key={headerID}></div>
                      <DraggableColumnHeader
                        key={headerID}
                        header={header}
                        tableInstance={tableInstance}
                        enableColumnDnD={enableColumnDnD}
                        left={scrollLeft}
                      />
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div className={classNames('tbody', css({ width: getTotalSize() }))}>
            {!getRowModel().rows.length && (
              <div className="tr">
                <td colSpan={columns.length} className={' w-full td px-2 font text-sm text-center'}>
                  No Rows To Show
                </td>
              </div>
            )}

            {(showPagination ? getRowModel().rows : getCoreRowModelInstance().flatRows).map(
              (row) => {
                const { id: rowID, getVisibleCells } = row;
                return (
                  <div className="tr" key={rowID} onDoubleClick={() => onRowDoubleClick(row)}>
                    {getVisibleCells().map((cell) => {
                      const { id: cellID, column, getContext } = cell;
                      const colStyles = getCommonPinningStyles(column, scrollLeft);
                      return (
                        <div
                          className={classNames(
                            'td px-2 font text-sm flex items-center ',
                            css({
                              flex: 1,
                              flexBasis: column.getSize(),
                              overflow: 'hidden',
                              height: '100%',
                            })
                          )}
                          // @ts-expect-error
                          style={colStyles}
                          key={cellID}
                          data-columnid={column.id}
                        >
                          {flexRender(column.columnDef.cell, getContext())}
                        </div>
                      );
                    })}
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>

      {showPagination && (
        <PaginationContainer
          tableInstance={tableInstance}
          server={server}
          getData={getData}
          totalCount={totalCount}
          showPagination={pagination}
          globalFilter={globalFilter}
          sorting={sorting}
          showRecordperPage={showRecordperPage}
        />
      )}

      {!showPagination && enableRowCount && (
        <span>
          {getCoreRowModelInstance().flatRows.length !== 0 && (
            <span>
              Showing 1 to {getCoreRowModelInstance().flatRows.length} of{' '}
              {getCoreRowModelInstance().flatRows.length}{' '}
            </span>
          )}
        </span>
      )}
    </>
  );
}

const tableClass = (width: number | string) =>
  css({
    width,
  });
