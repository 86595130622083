export const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || 'https://v5report.testrmsid.com/api/v1';
export const PUBLIC_URL = process.env.PUBLIC_URL || '';
export const SITE_KEY =
  process.env.REACT_APP_API_SITE_KEY || '6LeYYjcUAAAAAN23CX0kGLlAyobZ6ZZ-ACg9tFQo';
export const REDIRECT_URL_KEY = 'redirect';
export const MERCHANT_LOGO = process.env.REACT_APP_MERCHANT_LOGO || '';
export const ENABLE_STORAGE_ENCRYPTION = process.env.REACT_APP_ENABLE_STORAGE_ENCRYPTION === 'true';
export const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY || '';

export const STORAGE_KEYS = {
  AUTH_STATE: 'AUTH_STATE',
};
export const HASH_KEY = process.env.REACT_APP_HASH_KEY || '';
export const IV = process.env.REACT_APP_IV || '';
export const BASE_SOCKET_URL = process.env.REACT_APP_API_BASE_URL2 || '';
export const NOTIFICATION_SOCKET_URL = `${BASE_SOCKET_URL}/ws/notifications/`;
