import { useContext } from 'react';
import Radio, { RadioProps } from './Radio';
import { RadioGroupContext } from './RadioGroupContext';

export type RadioGroupItemProps = RadioProps & {};

export default function RadioGroupItem({ value, ...props }: RadioGroupItemProps) {
  const { value: checkedValue, onChange: onChecked } = useContext(RadioGroupContext);
  const handleOnChange = () => {
    if (onChecked) {
      onChecked(value);
    }
  };
  return (
    <Radio checked={value === checkedValue} value={value} onChange={handleOnChange} {...props} />
  );
}
