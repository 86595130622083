import classNames from 'classnames';
import { ChangeEvent, InputHTMLAttributes, ReactNode, useEffect, useState } from 'react';
import { cx } from 'utils/functions';
import { ThemeColors } from 'utils/types';
import { Label, LabelProps } from './Label';

type CheckBoxProps = InputHTMLAttributes<HTMLInputElement> & {
  children?: ReactNode;
  label?: string;
  labelProps?: LabelProps;
  color?: ThemeColors;
};

export default function CheckBox({
  children,
  labelProps,
  label,
  type = 'checkbox',
  color = 'primary',
  checked,
  onChange,
  ...props
}: CheckBoxProps) {
  const [state, setState] = useState(checked);

  useEffect(() => {
    setState(checked);
  }, [checked]);

  const switchClasses = classNames('switch', {
    'bg-slate-300': !state,
    [cx('bg', color)]: state,
  });

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setState(!state);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <Label check {...labelProps}>
      <input
        className="switch-input"
        type={type}
        checked={state}
        onChange={handleOnChange}
        {...props}
      />
      <div className={switchClasses}></div>
      {children || label}
    </Label>
  );
}
