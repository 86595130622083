// @ts-nocheck
import { pick } from 'lodash';
import { theme as defaultTheme } from 'tailwindcss/defaultConfig';
import resolveConfig from 'tailwindcss/resolveConfig';
import { PluginUtils } from 'tailwindcss/types/config';

const spacing = pick(defaultTheme?.spacing, [
  'px',
  '0',
  '0.5',
  '1',
  '2',
  '3',
  '4',
  '6',
  '8',
  '16',
  '24',
  '32',
]);

export const theme = {
  spacing,
  colors: ({ colors }: PluginUtils) => ({
    ...colors,
    primary: '#56b700',
    secondary: colors.slate['500'],
    success: colors.green['500'],
    danger: colors.red['500'],
    warning: colors.yellow['500'],
    info: colors.sky['500'],
    black: colors.black,
  }),
  borderColor: ({ theme }: PluginUtils) => ({
    ...theme('colors'),
    DEFAULT: theme('colors.neutral.400', 'currentColor'),
  }),
  fontSize: {
    xs: ['0.625rem', { lineHeight: '1rem' }],
    sm: ['0.75rem', { lineHeight: '1rem' }],
    base: ['0.875rem', { lineHeight: '1.25rem' }], // h6
    lg: ['1rem', { lineHeight: '1.5rem' }], // h5
    xl: ['1.125rem', { lineHeight: '1.75rem' }], // h4
    '2xl': ['1.25rem', { lineHeight: '1.75rem' }], // h3
    '3xl': ['1.5rem', { lineHeight: '2rem' }], // h2
    '4xl': ['1.875rem', { lineHeight: '2.25rem' }], // h1
    '5xl': ['2.25rem', { lineHeight: '2.5rem' }],
    '6xl': ['3rem', { lineHeight: '1' }],
    '7xl': ['3.75rem', { lineHeight: '1' }],
    '8xl': ['4.5rem', { lineHeight: '1' }],
    '9xl': ['6rem', { lineHeight: '1' }],
  },
  boxShadow: {
    shadowCard: '0 8px 30px 0 rgba(0,0,0,0.16)',
  },
  extend: {},
};

// @ts-ignore
export const { theme: themeConfig } = resolveConfig({ theme });
