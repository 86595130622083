import { animated, config, useSpring } from '@react-spring/web';
import { HTMLAttributes, useState } from 'react';
import useMeasure from 'react-use-measure';

type CollapseProps = HTMLAttributes<HTMLElement> & {
  isOpen: boolean;
};

function Collapse({ isOpen, ...props }: CollapseProps) {
  const [ref, { height }] = useMeasure();
  const [hide, setHide] = useState(!isOpen);
  const styles = useSpring({
    config: config.gentle,
    from: {
      height: 0,
    },
    to: {
      height: isOpen ? height : 0,
    },
    onStart: () => setHide(true),
    onResolve: () => setHide(false),
  });
  return (
    <animated.div className={hide || !isOpen ? `overflow-hidden` : ''} style={styles}>
      <div ref={ref} {...props} />
    </animated.div>
  );
}

export default Collapse;
