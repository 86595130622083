import { get } from 'lodash';
import { ComponentType, useEffect, useState } from 'react';
import {
  Field,
  FieldSelectorProps,
  Operator,
  OperatorSelectorProps,
  OptionList,
  QueryBuilder,
  QueryBuilderProps,
  RuleGroupType,
  RuleGroupTypeIC,
  ValueEditorProps,
} from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css';
import { MultiValue, SingleValue } from 'react-select';
import { Button } from './Button';
import Icon from './Icon';
import { Input } from './Input';
import { Option, Select } from './Select';

export type ReactQueryBuilderProps<RG extends RuleGroupType | RuleGroupTypeIC = RuleGroupType> =
  QueryBuilderProps<RG> & {
    fields: OptionList<Field> | Record<string, Field>;
    condition: OptionList<Operator>;
    query?: RG;
    onQueryChange?(query: RG): void;
  };

const FieldSelector: ComponentType<FieldSelectorProps> = (props) => {
  const [value, setValue] = useState<SingleValue<{ name: string; label: string }>>(null);
  // console.log('props', props);

  useEffect(() => {
    if (props && props.options && Array.isArray(props.options)) {
      const selectedOptions = props.options
        // @ts-ignore
        .reduce((final, item) => {
          if (item.options) {
            final = [...final, item.options];
          } else {
            final.push(item);
          }
          return final;
        }, [])
        .find((item: { name: string }) => item.name === props.value);
      setValue(selectedOptions);
    }
  }, []); // eslint-disable-line

  return (
    <Select
      className="w-full"
      isMulti={false}
      value={value}
      options={props.options}
      getOptionValue={(opt) => opt.name}
      getOptionLabel={(opt) => opt.label}
      onChange={(option) => {
        props.handleOnChange(get(option, 'name') || '');
        setValue(option);
      }}
    />
  );
};

const OperatorSelector: ComponentType<OperatorSelectorProps> = (props) => {
  const [selectedOperator, setSelectedOperator] = useState<
    SingleValue<{
      label: string;
      name: string;
    }>
    // @ts-ignore
  >(props.options.find((f) => get(f, 'name') === props.value) || null);
  useEffect(() => {
    props.handleOnChange(get(selectedOperator, 'name') || '');
  }, [selectedOperator]); // eslint-disable-line
  return (
    <Select
      className="w-full"
      isMulti={false}
      options={props.options}
      value={selectedOperator}
      getOptionLabel={(option) => get(option, 'label') || ''}
      getOptionValue={(option) => get(option, 'name') || ''}
      onChange={(option) => setSelectedOperator(option)}
    />
  );
};

const ValueEditor: ComponentType<ValueEditorProps> = (props) => {
  // console.log('props', props);
  const singleSelectOptions = get(props, 'values') || [];
  const [value, setValue] = useState<string>(props.value);
  const [selectedValue, setSelectedValue] = useState<SingleValue<ValueEditorProps['values']>>(
    singleSelectOptions.find((f) => f.name === props.value) || null
  );
  const [selectedValues, setSelectedValues] = useState<MultiValue<ValueEditorProps['values']>>([]);

  useEffect(() => {
    if (props.inputType === 'text') {
      props?.handleOnChange(value);
    }
    if (props.inputType === 'select') {
      props?.handleOnChange(get(selectedValue, 'name'));
    }
    if (props.inputType === 'multiselect') {
      props?.handleOnChange(selectedValues?.map((item) => get(item, 'name')));
    }
  }, [value, selectedValue, selectedValues]); // eslint-disable-line

  if (props.inputType === 'text') {
    return (
      <Input
        className="w-full"
        type={'text'}
        value={value}
        onChange={({ target }) => setValue(target.value)}
      />
    );
  }
  if (props.inputType === 'select') {
    return (
      <Select
        className="w-full"
        isMulti={false}
        options={singleSelectOptions}
        value={selectedValue}
        onChange={(option) => setSelectedValue(option)}
        getOptionLabel={(opt) => get(opt, 'label')}
        getOptionValue={(opt) => get(opt, 'name')}
      ></Select>
    );
  }
  if (props.inputType === 'multiselect') {
    return (
      <Select
        className="w-full"
        isMulti={true}
        options={get(props, 'values') || []}
        value={selectedValues}
        onChange={(option) => setSelectedValues(option)}
        getOptionLabel={(opt) => get(opt, 'label') || ''}
        getOptionValue={(opt) => get(opt, 'name') || ''}
      />
    );
  }
  return null;
};

function ReactQueryBuilder<RG extends RuleGroupType | RuleGroupTypeIC = RuleGroupType>({
  fields,
  condition,
  query,
  onQueryChange,
}: ReactQueryBuilderProps<RG>) {
  return (
    // @ts-ignore
    <QueryBuilder
      operators={condition}
      fields={fields}
      query={query}
      onQueryChange={onQueryChange}
      controlClassnames={{ queryBuilder: 'queryBuilder-branches' }}
      controlElements={{
        addGroupAction: (props) => {
          return <Button onClick={props.handleOnClick}>{props.title}</Button>;
        },
        addRuleAction: (props) => {
          return <Button onClick={props.handleOnClick}>{props.title}</Button>;
        },
        combinatorSelector: (props) => {
          return (
            <Select
              isMulti={false}
              value={{ label: props?.value?.toUpperCase() || '', value: props?.value || '' }}
              onChange={(option) => {
                return props.handleOnChange(option?.value || '');
              }}
            >
              {(props.options || []).map((item, inx) => {
                return (
                  <Option value={get(item, 'name')} key={'renderOperator-' + inx}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          );
        },
        fieldSelector: FieldSelector,
        operatorSelector: OperatorSelector,
        removeGroupAction: (props) => {
          return (
            <Button
              type="button"
              color="danger"
              outline
              onClick={props?.handleOnClick}
              className="mx-1 border-none"
              size="sm"
            >
              <Icon name="times" />
            </Button>
          );
        },
        removeRuleAction: (props) => {
          return (
            <Button
              type="button"
              color="danger"
              outline
              onClick={props?.handleOnClick}
              className="mx-1 border-none"
              size="sm"
            >
              <Icon name="times" />
            </Button>
          );
        },
        valueEditor: ValueEditor,
      }}
    />
  );
}

export default ReactQueryBuilder;
