import classNames from 'classnames';
import { HTMLAttributes } from 'react';

type FormFeedbackProps = HTMLAttributes<HTMLElement> & {
  tag?: keyof HTMLElementTagNameMap;
  valid?: boolean;
  invalid?: boolean;
};

export function FormFeedback({
  tag: Tag = 'div',
  className,
  valid,
  invalid,
  ...props
}: FormFeedbackProps) {
  const formFeedbackClass = {
    'text-success': valid,
    'text-danger': invalid,
  };
  const classes = classNames('text-secondary text-sm', formFeedbackClass, className);
  return <Tag className={classes} {...props} />;
}
