import classNames from 'classnames';
import Icon from '../Icon';

type PaginationProps = {
  currentPage: number;
  totalPages: number;
  siblingCount: number;
  showNext: boolean;
  showPrev: boolean;
  showControls?: boolean;
  onChange: (page: number) => void;
  previousPage: () => void;
  nextPage: () => void;
};

export default function Pagination({
  currentPage,
  totalPages,
  siblingCount,
  showNext,
  showPrev,
  showControls = true,
  onChange = () => {},
  previousPage = () => {},
  nextPage = () => {},
  ...props
}: PaginationProps) {
  const startPage = currentPage - siblingCount;
  const endPage = currentPage + siblingCount + 1;
  const range = [];
  const rangeWithDots: Array<{ label: string; value: number }> = [];
  let l;

  for (let i = 1; i <= totalPages; i++) {
    if (i === 1 || i === totalPages || (i >= startPage && i < endPage)) {
      range.push(i);
    }
  }

  for (let i of range) {
    if (l) {
      if (siblingCount === 1) {
        if (i - l !== 1) {
          rangeWithDots.push({ label: '...', value: -1 });
        }
      } else {
        if (i - l === siblingCount) {
          rangeWithDots.push({ label: '...', value: -1 });
        } else if (i - l !== 1) {
          rangeWithDots.push({ label: '...', value: -1 });
        }
      }
    }
    rangeWithDots.push({ label: `${i}`, value: i });
    l = i;
  }

  return (
    <div className={'inline-grid grid-flow-col gap-1'}>
      {showControls && (
        <div
          className={
            'w-8 h-8 flex items-center justify-center cursor-pointer rounded shadow text-primary bg-white'
          }
          onClick={() => showPrev && previousPage()}
        >
          <Icon name="arrow-left" />
        </div>
      )}
      {rangeWithDots.map((item, index) => {
        return (
          <div
            key={index}
            className={classNames(
              'w-8 h-8 flex items-center justify-center cursor-pointer rounded',
              {
                'bg-primary text-white': item.value === currentPage,
              }
            )}
            onClick={() => item.value !== -1 && onChange(item.value)}
          >
            {item.label}
          </div>
        );
      })}
      {showControls && (
        <div
          className={
            'w-8 h-8 flex items-center justify-center cursor-pointer rounded shadow text-primary bg-white'
          }
          onClick={() => showNext && nextPage()}
        >
          <Icon name="arrow-right" />
        </div>
      )}
    </div>
  );
}
