import { default as classNames } from 'classnames';
import { HTMLAttributes } from 'react';
import { cx } from 'utils/functions';

type CardFooterProps = HTMLAttributes<HTMLElement> & {
  tag?: keyof HTMLElementTagNameMap;
  px?: number;
  py?: number;
  noPadding?: boolean;
  borderTop?: boolean;
};

export function CardFooter({
  tag: Tag = 'div',
  children,
  className,
  py = 2,
  px = 4,
  noPadding,
  borderTop,
  ...props
}: CardFooterProps) {
  const CardFooterClasses = {
    [cx('px', px)]: !noPadding && px,
    [cx('py', py)]: !noPadding && py,
    'border-t': borderTop,
  };
  const classes = classNames(CardFooterClasses, className);
  return (
    <Tag className={classes} {...props}>
      {children}
    </Tag>
  );
}
