import { css } from '@emotion/css';
import { animated, useTransition } from '@react-spring/web';
import classNames from 'classnames';
import { HTMLAttributes, ReactNode } from 'react';
import { cx } from 'utils/functions';
import { ThemeSizes } from 'utils/types';
import Portal from './Portal';

type ModalProps = HTMLAttributes<HTMLElement> & {
  tag?: keyof HTMLElementTagNameMap;
  isOpen?: boolean;
  children: ReactNode;
  size?: ThemeSizes;
  containerClassName?: string;
  contantClassName?: string;
  justify?: 'start' | 'center' | 'end';
  onClose?: () => void;
};

const modalContentCSS = {
  start: css({
    position: 'fixed',
    left: 0,
  }),
  end: css({
    position: 'fixed',
    right: 0,
  }),
  center: css({
    position: 'fixed',
    left: 0,
    right: 0,
  }),
};

const animationsCSS = {
  enter: {
    start: 'translateX(0%)',
    center: 'scale(1)',
    end: 'translateX(0%)',
  },
  leave: {
    start: 'translateX(-60%)',
    center: 'scale(0.8)',
    end: 'translateX(60%)',
  },
};

export default function Modal({
  isOpen,
  children,
  onClose,
  size = 'md',
  containerClassName,
  contantClassName,
  justify = 'center',
  ...props
}: ModalProps) {
  const transitions = useTransition(isOpen, {
    from: {
      opacity: 0,
      transform: animationsCSS.leave[justify],
    },
    enter: {
      opacity: 1,
      transform: animationsCSS.enter[justify],
    },
    leave: {
      opacity: 0,
      transform: animationsCSS.leave[justify],
    },
    config: {
      duration: 150,
    },
  });

  const modalClasses = classNames('modal');

  const modalContentClasses = classNames(
    'modal-content',
    modalContentCSS[justify],
    {
      [cx('modal-content', size)]: size,
      'mr-auto ml-0': justify === 'start',
      'ml-auto mr-0': justify === 'end',
      'mx-auto': justify === 'center',
    },
    contantClassName
  );

  return transitions(
    (style, isOpen) =>
      isOpen && (
        <Portal>
          <div className={classNames('modal-container', containerClassName)}>
            <div className={modalClasses}>
              <animated.div style={style} className={modalContentClasses}>
                {children}
              </animated.div>
            </div>
            <div className="modal-backdrop" onClick={onClose}></div>
          </div>
        </Portal>
      )
  );
}
